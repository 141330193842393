import React from 'react';
import styles from './CustomNode.module.scss';
import TreeEditorConfig from '../../TreeEditorConfig';
import TeamGoalNode from '../GoalNode/TeamGoalNode/TeamGoalNode';
import GroupNode from '../GroupNode/GroupNode';
import FormGoalNode from '../FormGoalNode/FormGoalNode';
import AdministratorGoalNode from '../GoalNode/AdministratorGoalNode/AdministratorGoalNode';

export default function CustomNode(props) {
  const { nodeDatum, isEditable: initialIsEditable, variant, teamId } = props;
  const {
    attributes: { nodeType, id, isTeamGoal },
  } = nodeDatum;
  let node;

  const isEditable = initialIsEditable && (!teamId || isTeamGoal);

  switch (nodeType) {
    case 'GROUP':
      node = (
        <GroupNode key={id} nodeDatum={nodeDatum} isEditable={isEditable} />
      );
      break;
    case 'GOAL':
      if (variant === 'form') {
        node = (
          <FormGoalNode
            key={id}
            nodeDatum={nodeDatum}
            isEditable={isEditable}
          />
        );
        break;
      }
      if (teamId) {
        node = (
          <TeamGoalNode
            key={id}
            nodeDatum={nodeDatum}
            isEditable={isEditable}
            teamId={teamId}
          />
        );
        break;
      }
      node = (
        <AdministratorGoalNode
          key={id}
          nodeDatum={nodeDatum}
          isEditable={isEditable}
        />
      );
      break;
  }

  return (
    <foreignObject
      x={TreeEditorConfig[variant].nodeSize.x / -2}
      y={TreeEditorConfig[variant].nodeSize.y / -2}
      width={TreeEditorConfig[variant].nodeSize.x}
      height={TreeEditorConfig[variant].nodeSize.y}
    >
      <div className={styles.node}>{node}</div>
    </foreignObject>
  );
}
