import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import styles from './AdministratorGoalNode.module.scss';
import GoalProgressChart from '../GoalProgressChart/GoalProgressChart';
import ProgressBar from '../../../../ProgressBar/ProgressBar';
import { AdministratorLogMeasurementDialog } from '../../../../LogMeasurementDialog/LogMeasurementDialog';
import useTreeEditor from '../../../../../../../hooks/useTreeEditor';
import { getAbsoluteTimestamp } from '../../../../../../../util/formatDate';
import TreeNodeTeamAssignmentDialog from '../../../../TreeNodeTeamAssignmentDialog/TreeNodeTeamAssignmentDialog';

function getDurationProgress(start, end, now) {
  if (now > end) {
    return 1;
  }
  if (now < start) {
    return 0;
  }
  return (now - start) / (end - start);
}

export default function AdministratorGoalNode(props) {
  const [isLogMeasurementDialogOpen, setIsLogMeasurementDialogOpen] =
    React.useState(false);
  const [isTeamAssignmentDialogOpen, setIsTeamAssignmentDialogOpen] =
    React.useState(false);
  const { reload, teams } = useTreeEditor();
  const { nodeDatum } = props;
  const {
    attributes: {
      id,
      label,
      isTeamGoal,
      description,
      startValue,
      endValue,
      startTime,
      endTime,
      goalMeasures,
      teams: goalTeams,
    },
  } = nodeDatum;

  const currentValue =
    goalMeasures.length > 0
      ? goalMeasures[goalMeasures.length - 1].value
      : startValue;

  const startTimeMs = new Date(startTime).getTime();
  const endTimeMs = new Date(endTime).getTime();
  const nowMs = new Date().getTime();
  const timeProgress = getDurationProgress(startTimeMs, endTimeMs, nowMs);
  const goalProgress = (currentValue - startValue) / (endValue - startValue);
  const goalProgressDesiredValue = Math.floor(
    timeProgress * (endValue - startValue) + startValue,
  );
  const goalProgressDesired =
    (goalProgressDesiredValue - startValue) / (endValue - startValue);

  return (
    <div
      className={`${styles.administratorGoalNode} ${isTeamGoal ? styles.teamGoal : ''}`}
    >
      <AdministratorLogMeasurementDialog
        goal={{ id, label, goalMeasures }}
        isOpen={isLogMeasurementDialogOpen}
        close={() => setIsLogMeasurementDialogOpen(false)}
        reload={reload}
      />
      <TreeNodeTeamAssignmentDialog
        goal={nodeDatum.attributes}
        teams={teams}
        isOpen={isTeamAssignmentDialogOpen}
        close={() => setIsTeamAssignmentDialogOpen(false)}
        reload={reload}
      />
      <div className={styles.innerWrapper}>
        <Link to={`/administrator/goals/${id}`}>
          <div className={`${styles.row} ${styles.top}`}>
            <p className={styles.title}>
              {label}
              <Tooltip title={description}>
                <span className={styles.icon}>
                  <HelpOutlineOutlinedIcon fontSize="inherit" />
                </span>
              </Tooltip>
            </p>
          </div>
        </Link>

        {(isTeamGoal && (
          <div className={styles.row}>
            <Link
              to={`/administrator/teams/${goalTeams[0].id}`}
              className={styles.teams}
            >
              <span className={styles.teamName}>{goalTeams[0].label}</span>
            </Link>
            <p className={styles.teamGoalIndicator}>Team Goal</p>
          </div>
        )) || (
          <>
            <div className={styles.row}>
              <p className={styles.label}>Team{goalTeams.length > 1 && 's'}:</p>
            </div>
            {(goalTeams.length === 0 && (
              <div className={`${styles.row} ${styles.noPaddingTop}`}>
                <p className={styles.noData}>Not assigned</p>
                <IconButton
                  className={styles.button}
                  onClick={() => setIsTeamAssignmentDialogOpen(true)}
                >
                  <GroupAddOutlinedIcon fontSize="inherit" />
                </IconButton>
              </div>
            )) || (
              <div className={`${styles.row} ${styles.noPaddingTop}`}>
                <p className={styles.teams}>
                  <Link to={`/administrator/teams/${goalTeams[0].id}`}>
                    <span className={styles.teamName}>
                      {goalTeams[0].label}
                    </span>
                  </Link>

                  {goalTeams.length > 1 && (
                    <Link to={`/administrator/goals/${id}`}>
                      <span className={styles.andMore}>
                        +{goalTeams.length - 1} more...
                      </span>
                    </Link>
                  )}
                </p>
                <IconButton
                  className={styles.button}
                  onClick={() => setIsTeamAssignmentDialogOpen(true)}
                >
                  <GroupAddOutlinedIcon fontSize="inherit" />
                </IconButton>
              </div>
            )}
          </>
        )}
        <div className={`${styles.row} ${styles.noPaddingTop}`}>
          <p className={styles.label}>Goal Progress:</p>
          <IconButton
            className={styles.button}
            onClick={() => setIsLogMeasurementDialogOpen(true)}
          >
            <AddchartOutlinedIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Tooltip
          title={`Current: ${currentValue}, optimal: ${goalProgressDesiredValue}`}
        >
          <div className={styles.tooltipInnerWrapper}>
            <div className={`${styles.row} ${styles.noPaddingTop}`}>
              <ProgressBar
                value={goalProgress}
                color="linear-gradient(90deg, rgb(158 212 131), rgb(146 234 178))"
                trailingDot
                trailingDotColor="rgb(105 184 105)"
              />
            </div>
            <div className={`${styles.row} ${styles.noPaddingTop}`}>
              <ProgressBar
                value={goalProgressDesired}
                color="linear-gradient(90deg, rgb(127 151 230), rgb(176 221 254))"
                trailingDot
                trailingDotColor="rgb(103 155 219)"
              />
            </div>
            <div className={`${styles.row} ${styles.noPaddingTop}`}>
              <p className={styles.value}>{startValue}</p>
              <p className={styles.value}>{endValue}</p>
            </div>
          </div>
        </Tooltip>
        <div className={styles.row}>
          <p className={styles.label}>Time:</p>
        </div>
        <div className={`${styles.row} ${styles.noPaddingTop}`}>
          <ProgressBar
            value={timeProgress}
            color="linear-gradient(90deg, rgb(212 141 186), rgb(214 183 255)"
            trailingDot
            trailingDotColor="rgb(170 124 232)"
          />
        </div>
        <div className={`${styles.row} ${styles.noPaddingTop}`}>
          <p className={styles.value}>
            {getAbsoluteTimestamp(new Date(startTime))}
          </p>
          <p className={styles.value}>
            {getAbsoluteTimestamp(new Date(endTime))}
          </p>
        </div>
        <div className={styles.chart}>
          <GoalProgressChart
            label={label}
            startValue={startValue}
            endValue={endValue}
            startTime={startTime}
            endTime={endTime}
            goalMeasures={goalMeasures}
          />
        </div>
      </div>
    </div>
  );
}
