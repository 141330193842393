import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import NotFound from '../../Common/NotFound/NotFound';
import AuthenticatedUserRoute from '../../../_functional/AuthenticatedUserRoute';
import Team from '../Team/Team/Team';
import Configuration from '../Team/Configuration/Configuration';
import TeamCommitments from '../Team/Commitments/Commitments';
import Commitments from '../Commitments/Commitments';
import Meeting from '../Team/Meetings/Meeting/Meeting';
import TeamMembers from '../Team/TeamMembers/TeamMembers/TeamMembers';
import EditTeamMembers from '../Team/TeamMembers/EditTeamMembers/EditTeamMembers';
import InviteTeamMember from '../Team/TeamMembers/InviteTeamMember/InviteTeamMember';
import Meetings from '../Team/Meetings/Meetings/Meetings';
import Goals from '../Team/Goals/Goals/Goals';
import GoalHierarchy from '../Team/Goals/GoalHierarchy/GoalHierarchy';
import EditGoalHierarchy from '../Team/Goals/GoalHierarchy/EditGoalHierarchy/EditGoalHierarchy';
import Goal from '../Team/Goals/Goal/Goal';

function UserLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="commitments" />} />
      <Route path="teams/:teamId/*">
        {/*<Route index element={<Team />} />*/}
        <Route index element={<Navigate to="goals" />} />
        <Route path="teamMembers/*">
          <Route index element={<TeamMembers />} />
          <Route path="edit" element={<EditTeamMembers />} />
          <Route path="invite" element={<InviteTeamMember />} />
        </Route>
        <Route path="meetings/*">
          <Route index element={<Meetings />} />
          <Route path=":meetingId" element={<Meeting />} />
        </Route>
        <Route path="goals/*">
          <Route index element={<Goals />} />
          <Route path="hierarchy/*">
            <Route index element={<GoalHierarchy />} />
            <Route path="edit/*" element={<EditGoalHierarchy />} />
          </Route>
          <Route path=":goalId">
            <Route index element={<Goal />} />
          </Route>
        </Route>
        <Route path="commitments" element={<TeamCommitments />} />
        <Route path="configuration" element={<Configuration />} />
      </Route>
      <Route path="commitments/*">
        <Route index element={<Navigate to="current" />} />
        <Route path=":queryType" element={<Commitments />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AuthenticatedUserRoute(UserLayout);
