import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import styles from './Team.module.scss';
import Table from '../../../../Common/Table/Table/Table';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import ConfirmationDialog from '../../../../Common/ConfirmationDialog/ConfirmationDialog';
import APIClient from '../../../../../../util/APIClient';
import FormAlert from '../../../../Common/FormAlert/FormAlert';

function teamMemberSortFn(a, b) {
  if (a.isLead && b.isLead) {
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  }
  if (a.isLead) {
    return -1;
  }
  if (b.isLead) {
    return 1;
  }
  if (a.fullName < b.fullName) {
    return -1;
  }
  if (a.fullName > b.fullName) {
    return 1;
  }
  return 0;
}

export default function Team() {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [formAlert, setFormAlert] = React.useState(null);
  const { data, error, isLoading } = useApiData({
    path: `/administrator/teams/${teamId}`,
    params: {
      includeGoals: true,
      includeTeamMembers: true,
    },
  });

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await APIClient.delete(`/administrator/teams/${teamId}`);
      setFormAlert({
        severity: 'success',
        title: 'Team deleted successfully!',
        message:
          'Your team has been deleted successfully. You will be redirected shortly.',
      });
      setTimeout(() => {
        navigate(`/administrator/teams`);
      }, 3000);
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not delete team!',
        message:
          'Something went wrong during team deletion. Please contact the administrator for further support.',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return <Loading message="Fetching team..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const userData = data.teamMembers.map((t) => ({
    ...t,
    fullName: `${t.firstName} ${t.lastName}`,
  }));

  userData.sort(teamMemberSortFn);

  return (
    <div className={styles.team}>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        close={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title="Are you sure?"
        description={`You are about to permanently delete the team "${data.label}". All users who are members of this team will be removed from the team, but their user accounts or any other teams they belong to will remain operational.`}
        confirmText="Delete Team"
        confirmButtonColor="error"
      />
      {formAlert && <FormAlert alert={formAlert} />}
      <Table
        rows={userData}
        keyColumn="cognitoId"
        topButtons={[
          {
            label: 'Edit Team',
            to: 'edit',
            startIcon: <EditOutlinedIcon />,
          },
          {
            label: 'Delete Team',
            handler: () => {
              setIsDeleteDialogOpen(true);
            },
            startIcon: <DeleteForeverOutlinedIcon />,
            isLoading: isDeleting,
            color: 'error',
          },
        ]}
        allowSort
        linkFunction={(id) => `teamMembers/${id}`}
        title={`Team: ${data.label}`}
        rowLinks={{
          fullName: {
            label: 'View User',
            to: (id) => `/administrator/users/${id}`,
          },
        }}
        columns={[
          {
            id: 'firstName',
            label: 'First Name',
            numeric: false,
          },
          {
            id: 'lastName',
            label: 'Last Name',
            numeric: false,
          },
          {
            id: 'isLead',
            label: 'Team Leader',
            boolean: true,
          },
          {
            id: 'isEnabled',
            label: 'Enabled',
            boolean: true,
          },
        ]}
      />
    </div>
  );
}
