import React from 'react';
import styles from './InstructionsSectionImage.module.scss';

export default function InstructionsSectionImage(props) {
  const { src, alt, description } = props;

  return (
    <div className={styles.instructionsSectionImage}>
      <img src={src} alt={alt} />
      <p>{description}</p>
    </div>
  );
}
