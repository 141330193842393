import React from 'react';
import styles from './Meetings.module.scss';
import { useParams } from 'react-router-dom';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import { getAbsoluteTimestamp } from '../../../../../../util/formatDate';
import Table, { MEETING_STATUS } from '../../../../Common/Table/Table/Table';
import NextMeetingBanner from './NextMeetingBanner/NextMeetingBanner';

function getSortOrderFromStatus(status) {
  switch (status) {
    case 'SCHEDULED':
      return 4;
    case 'UPCOMING':
      return 3;
    case 'OVERDUE':
      return 2;
    case 'IN_PROGRESS':
      return 1;
    default:
      return 10;
  }
}

function meetingSortFn(a, b) {
  const statusA = getSortOrderFromStatus(a);
  const statusB = getSortOrderFromStatus(b);

  if (statusA < statusB) {
    return -1;
  }
  if (statusA > statusB) {
    return 1;
  }
  if (a.scheduledTime < b.scheduledTime) {
    return -1;
  }
  if (a.scheduledTime < b.scheduledTime) {
    return 1;
  }
  return 0;
}

export default function Meetings() {
  const { teamId } = useParams();
  const { data, error, isLoading } = useApiData({
    path: `/user/teams/${teamId}/meetings`,
  });

  if (isLoading) {
    return <Loading message="Fetching meetings..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const formattedData = data.map((m) => ({
    ...m,
    startingWeek: getAbsoluteTimestamp(new Date(m.minTime)),
    attended: ['PRESENT', 'JOINED'].includes(m.attendance),
    scheduledTime: getAbsoluteTimestamp(new Date(m.scheduledTime), true),
  }));

  const nextMeetings = data.filter((m) =>
    ['SCHEDULED', 'UPCOMING', 'IN_PROGRESS', 'OVERDUE'].includes(m.status),
  );
  nextMeetings.sort(meetingSortFn);

  return (
    <div className={styles.meetings}>
      {nextMeetings.slice(0, 1).map((m) => (
        <NextMeetingBanner key={`${m.id}-nextMeetingBanner`} meeting={m} />
      ))}
      <Table
        rows={formattedData}
        title="Meetings"
        defaultSortKey="minTime"
        defaultSortOrder="desc"
        columns={[
          {
            id: 'scheduledTime',
            label: 'Meeting time',
          },
          {
            id: 'attended',
            label: 'Attended',
            boolean: true,
          },
          {
            id: 'startingWeek',
            label: 'Starting week',
          },
          {
            id: 'status',
            label: 'Status',
            enum: MEETING_STATUS,
          },
        ]}
      />
    </div>
  );
}
