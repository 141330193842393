import React from 'react';
import { useParams } from 'react-router-dom';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import styles from './Configuration.module.scss';
import APIClient from '../../../../../util/APIClient';
import Form from '../../../Common/Form/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import FormAlert from '../../../Common/FormAlert/FormAlert';
import FormSelect from '../../../Common/FormSelect/FormSelect';
import FormTimeInput from '../../../Common/FormTimeInput/FormTimeInput';
import {
  getAbsoluteTimestamp,
  TIMEZONES,
} from '../../../../../util/formatDate';
import FormTimezoneInput from '../../../Common/FormTimezoneInput/FormTimezoneInput';
const DAY_OF_WEEK_OPTIONS = [
  {
    key: 'monday',
    value: 1,
    label: 'Monday',
  },
  {
    key: 'tuesday',
    value: 2,
    label: 'Tuesday',
  },
  {
    key: 'wednesday',
    value: 3,
    label: 'Wednesday',
  },
  {
    key: 'thursday',
    value: 4,
    label: 'Thursday',
  },
  {
    key: 'friday',
    value: 5,
    label: 'Friday',
  },
  {
    key: 'saturday',
    value: 6,
    label: 'Saturday',
  },
  {
    key: 'sunday',
    value: 0,
    label: 'Sunday',
  },
];

let localTimezone = 'Europe/London';
try {
  localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
} catch (e) {
  console.warn(e);
  console.warn(
    'Failed to detect the local timezone! Defaults will be set to "Europe/London".',
  );
}

export default function Configuration() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [formAlert, setFormAlert] = React.useState(null);
  const { teamId } = useParams();
  const { data, error, isLoading, reload } = useApiData({
    path: `/user/teams/${teamId}`,
  });

  if (isLoading) {
    return <Loading message="Fetching team..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  async function onSubmit(submissionData) {
    setIsSubmitting(true);
    try {
      const updatedConfiguration = await APIClient.put(
        `/user/teams/${teamId}`,
        {
          timezone: submissionData.timezone,
          meetingDayOfWeek: submissionData.meetingDayOfWeek,
          meetingHour: submissionData.time.getHours(),
          meetingMinute: submissionData.time.getMinutes(),
        },
      ).then((result) => result.data);

      setHasSubmitted(true);
      setFormAlert({
        severity: 'success',
        title: 'Configuration updated successfully!',
        message: `Configuration has been successfully updated. Changes to the meeting schedule will take place immediately. Your next meeting is scheduled for ${getAbsoluteTimestamp(new Date(updatedConfiguration.nextMeeting.scheduledTime), true)}.`,
      });
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not update configuration!',
        message:
          'Something went wrong during the configuration update. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.configuration}>
      <p className={styles.description}>
        Use this form to update the configuration schedule for the team.
        Changing the meeting schedule will take immediate effect and any
        meetings already scheduled will be updated.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <div className={styles.horizontalInputs}>
          <FormSelect
            name="meetingDayOfWeek"
            label="Day of the week"
            options={DAY_OF_WEEK_OPTIONS}
            defaultValue={
              DAY_OF_WEEK_OPTIONS.filter(
                (o) => o.value === data.meetingDayOfWeek,
              )[0].value || DAY_OF_WEEK_OPTIONS[0].value
            }
            variant="outlined"
            rules={{
              required: {
                value: true,
                message: 'Day of the week is required.',
              },
            }}
          />
          <FormTimeInput
            name="time"
            label="Time"
            defaultValue={
              new Date(
                new Date().setHours(data.meetingHour, data.meetingMinute, 0, 0),
              )
            }
          />
        </div>
        <div className={styles.timezone}>
          <FormTimezoneInput initialTimezone={data.timezone || localTimezone} />
        </div>
        <div className={styles.formControls}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={hasSubmitted}
            fullWidth
          >
            Update
          </LoadingButton>
        </div>
        <div className={styles.alert}>
          {formAlert && <FormAlert alert={formAlert} />}
        </div>
      </Form>
    </div>
  );
}
