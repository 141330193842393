import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './DetailedGoalProgressChart.module.scss';
import { getAbsoluteTimestamp } from '../../../../../../../util/formatDate';

function EmptyChart() {
  return (
    <div className={styles.goalProgressChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={[]}
          className={styles.chart}
          margin={{
            top: 1,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <CartesianGrid stroke="#ddd" strokeWidth={1} strokeDasharray="4 4" />
          <text
            className={styles.noData}
            x="50%"
            y="50%"
            stroke="none"
            textAnchor="middle"
            dy={8}
          >
            No data
          </text>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default function DetailedGoalProgressChart(props) {
  const { startValue, endValue, goalMeasures, startTime, endTime } = props;

  if (goalMeasures.length === 0) {
    return <EmptyChart />;
  }

  const chartData = [
    {
      time: new Date(startTime).getTime(),
      desiredValue: startValue,
      value: startValue,
    },
    ...goalMeasures.map((measure) => ({
      time: new Date(measure.time).getTime(),
      value: measure.value,
    })),
    { time: new Date(endTime).getTime(), desiredValue: endValue },
  ].sort((a, b) => {
    if (a.time < b.time) {
      return -1;
    }
    if (a.time > b.time) {
      return 1;
    }
    return 0;
  });

  if (chartData[chartData.length - 1].time > new Date(endTime).getTime()) {
    chartData[chartData.length - 1].goalExtension = endValue;
    const desiredValues = chartData.filter((p) =>
      Object.hasOwnProperty.call(p, 'desiredValue'),
    );
    desiredValues[desiredValues.length - 1].goalExtension = endValue;
  }

  const measureMaxX = Math.max(
    ...goalMeasures.map((p) => new Date(p.time).getTime()),
  );

  const measureMinY = Math.min(...goalMeasures.map((p) => p.value));
  const measureMaxY = Math.max(...goalMeasures.map((p) => p.value));

  const dataMinX = Math.min(...chartData.map((p) => p.time));
  const dataMaxX = Math.max(...chartData.map((p) => p.time));

  let domainX = [dataMinX, dataMaxX];
  let domainY = [
    Math.min(startValue, measureMinY),
    Math.max(endValue, measureMaxY),
  ];

  if (measureMaxX < new Date(endTime).getTime()) {
    // all data within goal boundaries, potentially trim graph
    const dataSpan = measureMaxX - dataMinX;
    const goalSpan = dataMaxX - dataMinX;
    if (dataSpan < goalSpan * 0.75) {
      domainX = [dataMinX, dataMinX + (new Date().getTime() - dataMinX) * 1.1];

      const yForX =
        ((domainX[1] - new Date(startTime).getTime()) /
          (new Date(endTime).getTime() - new Date(startTime).getTime())) *
          (endValue - startValue) +
        startValue;
      domainY = [domainY[0], Math.max(yForX, measureMaxY)];
    }
  }

  return (
    <div className={styles.goalProgressChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={chartData}
          className={styles.chart}
          margin={{
            top: 1,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <CartesianGrid stroke="#ddd" strokeWidth={1} strokeDasharray="4 4" />
          <XAxis
            type="number"
            dataKey="time"
            domain={domainX}
            allowDataOverflow
            tickFormatter={(value) => getAbsoluteTimestamp(new Date(value))}
            interval="preserveStart"
            stroke="#ccc"
            strokeWidth={1}
            tickCount={20}
            minTickGap={16}
          />
          <YAxis
            interval="preserveStart"
            tickCount={10}
            allowDecimals={false}
            mirror
            type="number"
            domain={domainY}
            allowDataOverflow
            stroke="#ccc"
            strokeWidth={1}
            minTickGap={16}
          />
          <Area
            connectNulls
            name="value"
            dataKey="value"
            stroke="#87d68a"
            strokeWidth={2}
            fill="#87d68a"
            fillOpacity={0.4}
          />
          <Line
            className={styles.unclipPath}
            connectNulls
            name="desiredValue"
            dataKey="desiredValue"
            stroke="#92b1ff"
            strokeWidth={2}
            dot={false}
          />
          <Line
            className={styles.unclipPath}
            connectNulls
            name="goalExtension"
            dataKey="goalExtension"
            stroke="#92b1ff"
            strokeWidth={2}
            strokeDasharray="12 4"
            dot={false}
          />
          <ReferenceLine
            x={new Date().getTime()}
            stroke="#ba97e8"
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
