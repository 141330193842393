import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { OutlinedInput } from '@mui/material';
import styles from './LogMeasurementDialog.module.scss';
import APIClient from '../../../../util/APIClient';
import { isNumeric } from '../../../../hooks/useTreeEditor';
import FormAlert from '../FormAlert/FormAlert';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../util/formatDate';
import { useParams } from 'react-router-dom';

function LogMeasurementDialog({
  goal,
  isOpen,
  close,
  reload = () => {},
  path,
}) {
  const [valueInput, setValueInput] = React.useState('');
  const [valueError, setValueError] = React.useState(null);
  const [timeInput, setTimeInput] = React.useState(new Date());
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (valueInput !== '') {
      if (!isNumeric(valueInput)) {
        setValueError('Value must be a number.');
        return;
      }
    }
    setValueError(null);
  }, [valueInput]);

  const handleClose = () => {
    setValueInput('');
    setValueError(null);
    setTimeInput(new Date());
    setFormAlert(null);
    setIsSubmitting(false);
    close();
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await APIClient.post(path, {
        time: new Date(timeInput.setHours(12, 0, 0, 0)),
        value: valueInput,
      });
      reload();
      handleClose();
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not log measurement!',
        message:
          'Something went wrong when logging the measurement. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAbort = () => {
    handleClose();
  };

  const handleFocusLost = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleFocusLost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Log measurement
      </DialogTitle>
      <div className={styles.content}>
        <DialogContentText id="alert-dialog-description">
          You are about to update the measurement for goal
          <span style={{ fontStyle: 'italic', paddingLeft: '.2rem' }}>
            {goal.label}
          </span>
          . This will update the{' '}
          <span style={{ fontWeight: 'bold' }}>total value</span> for the goal
          and its completion progress with the new value you provide. You can
          optionally set a specific date for which this value should be logged.
        </DialogContentText>
        <div className={styles.pastMeasures}>
          <p className={styles.label}>Recent measurements:</p>
          <div className={styles.measures}>
            {goal.goalMeasures.length > 3 && (
              <>
                <p>...</p>
                <p>...</p>
                <p>...</p>
              </>
            )}
            {goal.goalMeasures.slice(-3).map((m) => (
              <React.Fragment key={m.id}>
                <p>{m.value}</p>
                <p>{getAbsoluteTimestamp(new Date(m.time))}</p>
                <p>({getRelativeTimestamp(new Date(m.time))})</p>
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className={styles.form}>
          <FormControl
            sx={{ m: 1 }}
            error={!!valueError}
            // disabled={!isEditable}
            className={styles.formControl}
          >
            <InputLabel id="label-label">Value</InputLabel>
            <OutlinedInput
              className={styles.input}
              id="value-input"
              label="Value"
              value={valueInput}
              onChange={(e) => setValueInput(e.target.value)}
            />
            {valueError && <FormHelperText>{valueError}</FormHelperText>}
          </FormControl>
          <FormControl
            sx={{ m: 1 }}
            // disabled={!isEditable}
            className={styles.formControl}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id="time-input"
                value={timeInput}
                label="Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(value) => setTimeInput(value)}
                slotProps={{
                  textField: {
                    className: styles.input,
                    fullWidth: true,
                    variant: 'outlined',
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className={styles.buttons}>
          <Button onClick={handleAbort} color="primary" variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            disabled={valueInput === '' || !!valueError}
            form="logMeasurementFor"
            onClick={handleSubmit}
          >
            Log measurement
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </div>
    </Dialog>
  );
}

/**
 * Must be placed within a path that contains the teamId parameter.
 */
export function TeamLogMeasurementDialog(props) {
  const { teamId } = useParams();
  return (
    <LogMeasurementDialog
      {...props}
      path={`/user/teams/${teamId}/goals/${props.goal.id}/measures`}
    />
  );
}

export function AdministratorLogMeasurementDialog(props) {
  return (
    <LogMeasurementDialog
      {...props}
      path={`/administrator/goals/${props.goal.id}/measures`}
    />
  );
}
