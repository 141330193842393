import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './GoalProgressChart.module.scss';

function EmptyChart() {
  return (
    <div className={styles.goalProgressChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={[]}
          className={styles.chart}
          margin={{
            top: 1,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <CartesianGrid
            stroke="#ddd"
            strokeWidth={1}
            strokeDasharray="4 4"
            verticalCoordinatesGenerator={(p) =>
              Array.from(
                { length: 7 },
                (e, i) =>
                  p.offset.left +
                  ((p.width - p.offset.left - p.offset.right) / 7) * (i + 1),
              )
            }
          />
          <text
            className={styles.noData}
            x="50%"
            y="50%"
            stroke="none"
            textAnchor="middle"
            dy={8}
          >
            No data
          </text>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default function GoalProgressChart(props) {
  const { startValue, endValue, goalMeasures, startTime, endTime } = props;

  if (goalMeasures.length === 0) {
    return <EmptyChart />;
  }

  const chartData = [
    {
      time: new Date(startTime).getTime(),
      desiredValue: startValue,
      value: startValue,
    },
    ...goalMeasures.map((measure) => ({
      time: new Date(measure.time).getTime(),
      value: measure.value,
    })),
    { time: new Date(endTime).getTime(), desiredValue: endValue },
  ].sort((a, b) => {
    if (a.time < b.time) {
      return -1;
    }
    if (a.time > b.time) {
      return 1;
    }
    return 0;
  });

  if (chartData[chartData.length - 1].time > new Date(endTime).getTime()) {
    chartData[chartData.length - 1].goalExtension = endValue;
    const desiredValues = chartData.filter((p) =>
      Object.hasOwnProperty.call(p, 'desiredValue'),
    );
    desiredValues[desiredValues.length - 1].goalExtension = endValue;
  }
  const measureMaxX = Math.max(
    ...goalMeasures.map((p) => new Date(p.time).getTime()),
  );

  const measureMinY = Math.min(...goalMeasures.map((p) => p.value));
  const measureMaxY = Math.max(...goalMeasures.map((p) => p.value));

  const dataMinX = Math.min(...chartData.map((p) => p.time));
  const dataMaxX = Math.max(...chartData.map((p) => p.time));
  const dataMinY = Math.min(...chartData.map((p) => p.value || p.desiredValue));
  const dataMaxY = Math.max(...chartData.map((p) => p.value || p.desiredValue));

  let domainX = [dataMinX, dataMaxX];
  let domainY = [
    Math.min(startValue, measureMinY),
    Math.max(endValue, measureMaxY),
  ];

  if (measureMaxX < new Date(endTime).getTime()) {
    // all data within goal boundaries, potentially trim graph
    const dataSpan = measureMaxX - dataMinX;
    const goalSpan = dataMaxX - dataMinX;
    if (dataSpan < goalSpan * 0.75) {
      domainX = [dataMinX, dataMinX + (new Date().getTime() - dataMinX) * 1.1];

      const yForX =
        ((domainX[1] - new Date(startTime).getTime()) /
          (new Date(endTime).getTime() - new Date(startTime).getTime())) *
          (endValue - startValue) +
        startValue;
      domainY = [domainY[0], Math.max(yForX, measureMaxY)];
    }
  }

  return (
    <div className={styles.goalProgressChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={chartData}
          className={styles.chart}
          margin={{
            top: 1,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <CartesianGrid
            stroke="#ddd"
            strokeWidth={1}
            strokeDasharray="4 4"
            verticalCoordinatesGenerator={(p) =>
              Array.from(
                { length: 7 },
                (e, i) =>
                  p.offset.left +
                  ((p.width - p.offset.left - p.offset.right) / 7) * (i + 1),
              )
            }
          />
          <XAxis
            height={1}
            type="number"
            dataKey="time"
            domain={domainX}
            allowDataOverflow
            tick={false}
            interval="preserveStartEnd"
            stroke="#ccc"
            strokeWidth={1}
          />
          <YAxis
            // padding={{ top: 1 }}
            interval="preserveEnd"
            mirror
            type="number"
            domain={domainY}
            allowDataOverflow
            ticks={Array.from(
              { length: Math.ceil(dataMaxY - dataMinY + 1) },
              (e, i) => dataMinY + i,
            )}
            stroke="#ccc"
            strokeWidth={1}
            tick={false}
          />
          <Area
            connectNulls
            name="value"
            dataKey="value"
            stroke="#87d68a"
            strokeWidth={2}
            fill="#87d68a"
            fillOpacity={0.4}
          />
          <Line
            className={styles.unclipPath}
            connectNulls
            name="desiredValue"
            dataKey="desiredValue"
            stroke="#92b1ff"
            strokeWidth={2}
            dot={false}
          />
          <Line
            className={styles.unclipPath}
            connectNulls
            name="goalExtension"
            dataKey="goalExtension"
            stroke="#92b1ff"
            strokeWidth={2}
            strokeDasharray="12 4"
            dot={false}
          />
          <ReferenceLine
            x={new Date().getTime()}
            stroke="#ba97e8"
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
