import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import styles from './UserAttendanceForm.module.scss';
import usePostInitDebouncing from '../../../../../../../../hooks/usePostInitDebouncing';
import { useMeetingControls } from '../../../../../../../../hooks/useMeeting';

const ATTENDANCE_OPTIONS = [
  {
    key: 'PRESENT',
    value: 'PRESENT',
    label: 'Present',
    description:
      'Team member is present. This status denotes their attendance in the meeting without having to sign into the application using their own account.',
  },
  {
    key: 'ABSENT',
    value: 'ABSENT',
    label: 'Away on leave',
    description:
      'Team member is away on annual leave or otherwise engaged, but their absence during the meeting is justified. This status will not negatively affect their score.',
  },
  {
    key: 'AWOL',
    value: 'AWOL',
    label: 'Absent (without official leave)',
    description:
      'Team member is absent without the official leave. This status will have a negative impact on their score by effectively marking it as missed meeting.',
  },
  {
    key: 'JOINED',
    value: 'JOINED',
    label: 'Present (joined the meeting)',
    description:
      'Team member has joined the meeting. This status is equivalent to being "Present", with the exception of being applied automatically when the team member joins the meeting.',
  },
];

const SELECTABLE_ATTENDANCE_OPTIONS = [
  ATTENDANCE_OPTIONS[0],
  ATTENDANCE_OPTIONS[1],
  ATTENDANCE_OPTIONS[2],
];

export default React.memo(function UserAttendanceForm(props) {
  const { cognitoId, firstName, lastName, attendance } = props;
  const [attendanceInput, setAttendanceInput] = React.useState(
    attendance || '',
  );
  const [attendanceCurrent, setAttendanceCurrent] = React.useState(
    attendance || '',
  );
  const { updateAttendance } = useMeetingControls();

  React.useEffect(() => {
    const newAttendance = attendance || '';
    if (attendance !== attendanceInput) {
      setAttendanceInput(newAttendance);
      setAttendanceCurrent(newAttendance);
    }
  }, [attendance]);

  usePostInitDebouncing(
    () => {
      if (attendanceInput !== attendanceCurrent) {
        setAttendanceCurrent(attendanceInput);
        updateAttendance(cognitoId, attendanceInput);
      }
    },
    [attendanceInput],
    10,
  );

  const handleAttendanceInputChange = React.useCallback((e) => {
    setAttendanceInput(e.target.value);
  }, []);

  const attendanceSelect = React.useMemo(
    () => (
      <FormControl fullWidth>
        {/*<InputLabel id={`${cognitoId}-attendance-label`}>Attendance</InputLabel>*/}
        <Select
          variant="standard"
          labelId={`${cognitoId}-attendance-label`}
          id={`${cognitoId}-attendance`}
          label="Attendance"
          onChange={handleAttendanceInputChange}
          value={attendanceInput}
          renderValue={(selected) =>
            ATTENDANCE_OPTIONS.filter((o) => o.value === selected)?.[0]?.label
          }
        >
          {ATTENDANCE_OPTIONS.map((o) => (
            <Tooltip
              key={`${cognitoId}-attendance-${o.key}-tooltip`}
              title={
                <p>
                  <strong>{o.label}</strong> - <em>{o.description}</em>
                </p>
              }
              placement="right"
              value={o.value}
            >
              <MenuItem
                disabled={o.value === 'JOINED'}
                key={`${cognitoId}-attendance-${o.key}-item`}
              >
                {o.label}
              </MenuItem>
            </Tooltip>
          ))}
        </Select>
      </FormControl>
    ),
    [
      ATTENDANCE_OPTIONS,
      cognitoId,
      handleAttendanceInputChange,
      attendanceInput,
    ],
  );

  return (
    <div className={styles.userAttendanceForm}>
      <p className={styles.name}>
        {firstName} {lastName}
      </p>
      <div className={styles.select}>{attendanceSelect}</div>
    </div>
  );
});
