import React from 'react';
import styles from './Commitments.module.scss';
import { Link, useParams } from 'react-router-dom';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';

export default function Commitments() {
  // const { teamId } = useParams();
  // const { data, error, isLoading, reload } = useApiData({
  //   path: `/user/commitments`,
  // });
  //
  // if (isLoading) {
  //   return <Loading message="Fetching commitments..." />;
  // }
  //
  // if (error || data === null) {
  //   return getErrorComponentFromHttpError(error);
  // }

  return (
    <p>TEAM COMMITMENTS</p>
    // <div className={styles.teamOverview}>
    //   <p>Meetings {data.label}</p>
    //   {data.map((m) => (
    //     <div
    //       key={m.id}
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row',
    //         gap: '1em',
    //       }}
    //     >
    //       <Link to={m.id}>{m.id}</Link>
    //       <p>Scheduled for: {new Date(m.scheduledTime).toLocaleDateString()}</p>
    //     </div>
    //   ))}
    // </div>
  );
}
