import React from 'react';
import styles from './NextMeetingBanner.module.scss';
import useAuth from '../../../../../../../hooks/useAuth';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../../../../util/formatDate';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import APIClient from '../../../../../../../util/APIClient';
import FormAlert from '../../../../../Common/FormAlert/FormAlert';
import LoadingButton from '@mui/lab/LoadingButton';

function getBannerClassByStatus(status) {
  switch (status) {
    case 'SCHEDULED':
      return styles.scheduled;
    case 'UPCOMING':
      return styles.upcoming;
    case 'IN_PROGRESS':
      return styles.inProgress;
    case 'OVERDUE':
      return styles.overdue;
  }
}

function getBannerStatusTitle(meeting) {
  switch (meeting.status) {
    case 'SCHEDULED':
      return `Scheduled Meeting`;
    case 'UPCOMING':
      return 'Upcoming Meeting';
    case 'IN_PROGRESS':
      return 'Meeting in Progress!';
    case 'OVERDUE':
      return 'Meeting Overdue!';
    default:
      return `Meeting`;
  }
}

function getBannerStatusText(meeting, teamName = '', isLead = false) {
  let text;
  const scheduledTime = new Date(meeting.scheduledTime);
  const minTime = new Date(meeting.minTime);
  const maxTime = new Date(meeting.maxTime);
  switch (meeting.status) {
    case 'SCHEDULED':
      text = `Next ${teamName} meeting is scheduled for ${getAbsoluteTimestamp(scheduledTime, true)}. You will be able to start the meeting on ${getAbsoluteTimestamp(minTime)}.`;
      break;
    case 'UPCOMING':
      if (scheduledTime.getTime() - new Date().getTime() < 24 * 3600 * 1000) {
        text = `${teamName} meeting starts ${getRelativeTimestamp(scheduledTime, true)}.`;
      } else {
        text = `${teamName} meeting starts on ${getAbsoluteTimestamp(scheduledTime, true, true)}.`;
      }
      if (isLead) {
        text += ' You can start the meeting early should you choose to do so.';
      }
      break;
    case 'IN_PROGRESS':
      text = `Your ${teamName} meeting (scheduled for ${getAbsoluteTimestamp(scheduledTime)}) is happening right now. You can join it now by pressing the join button; you will be able to update your last week's commitments during the meeting.`;
      break;
    case 'OVERDUE':
      text = `${teamName} meeting is overdue, it should have started ${getRelativeTimestamp(scheduledTime, true)}!`;
      if (isLead) {
        text += ` You can start the meeting up until the end of ${getAbsoluteTimestamp(maxTime)}.`;
      }
      break;
    default:
      text = `${teamName} meeting`;
  }
  text = text.trim().replaceAll('  ', ' ');
  text = `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`;
  return text;
}

export default function NextMeetingBanner(props) {
  const { meeting } = props;
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const team = currentUser.teams.filter((t) => t.id === meeting.teamId)?.[0];
  const teamName = team?.label || '';
  const isLead = team?.isLead;

  async function onStartMeeting() {
    setIsSubmitting(true);
    try {
      await APIClient.patch(
        `/user/teams/${meeting.teamId}/meetings/${meeting.id}`,
        {
          action: 'START_MEETING',
        },
      );
      navigate(meeting.id);
    } catch {
      setIsSubmitting(false);
      setFormAlert({
        severity: 'error',
        title: 'Could not start meeting!',
        message:
          'Something went wrong when attempting to start the meeting. Please contact the administrator for further support.',
      });
    }
  }

  return (
    <>
      <div
        className={`${styles.nextMeetingBanner} ${getBannerClassByStatus(meeting.status)}`}
      >
        <div className={styles.stack}>
          <h1>{getBannerStatusTitle(meeting, teamName)}</h1>
          <p>{getBannerStatusText(meeting, teamName, isLead)}</p>
        </div>
        <div className={styles.button}>
          {meeting.status === 'IN_PROGRESS' && (
            <Button
              variant="outlined"
              endIcon={<LoginOutlinedIcon />}
              component={Link}
              to={meeting.id}
              color="inherit"
              sx={{ backgroundColor: 'rgba(255, 255, 255, .75)' }}
            >
              Join Meeting
            </Button>
          )}
          {['OVERDUE', 'UPCOMING'].includes(meeting.status) && isLead && (
            <LoadingButton
              variant="outlined"
              endIcon={<EventAvailableOutlinedIcon />}
              onClick={onStartMeeting}
              color="inherit"
              sx={{ backgroundColor: 'rgba(255, 255, 255, .75)' }}
              loading={isSubmitting}
            >
              Start Meeting
            </LoadingButton>
          )}
        </div>
      </div>

      {formAlert && <FormAlert alert={formAlert} />}
    </>
  );
}
