import React from 'react';
import styles from './FormTimezoneInput.module.scss';
import FormSelect from '../../Common/FormSelect/FormSelect';
import { TIMEZONES } from '../../../../util/formatDate';
import { useFormContext } from 'react-hook-form';
import useDidMount from '../../../../hooks/useDidMount';

const timezoneAreas = {};
TIMEZONES.forEach((timezone) => {
  const indexOfFirstSlash = timezone.indexOf('/');
  const a = timezone.slice(0, indexOfFirstSlash);
  const b = timezone.slice(indexOfFirstSlash + 1);
  timezoneAreas[a] = [...(timezoneAreas[a] || []), b];
});

const timezoneGlobalOptions = Object.keys(timezoneAreas).map((v) => ({
  label: v,
  value: v,
  key: v,
}));

export default function FormTimezoneInput(props) {
  const { initialTimezone = 'Europe/London' } = props;
  const initialGlobalArea = initialTimezone.slice(
    0,
    initialTimezone.indexOf('/'),
  );
  const initialLocalArea = initialTimezone.slice(
    initialTimezone.indexOf('/') + 1,
  );
  const [timezoneLocalOptions, setTimezoneLocalOptions] = React.useState(
    timezoneAreas[initialGlobalArea].map((v) => ({
      label: v.replaceAll(/[_/]/gi, ' '),
      value: v,
      key: v,
    })),
  );
  const { watch, setValue } = useFormContext();
  const [timezoneGlobalInput, timezoneLocalInput] = watch([
    'timezoneGlobal',
    'timezoneLocal',
  ]);
  const didMount = useDidMount();

  React.useEffect(() => {
    if (didMount) {
      setTimezoneLocalOptions(
        (timezoneAreas[timezoneGlobalInput] || []).map((v) => ({
          label: v.replaceAll(/[_/]/gi, ' '),
          value: v,
          key: v,
        })),
      );

      if (!timezoneAreas[timezoneGlobalInput].includes(timezoneLocalInput)) {
        console.log('clearing value');
        setValue('timezoneLocal', '');
      }
    }
    setValue('timezone', `${timezoneGlobalInput}/${timezoneLocalInput}`);
  }, [timezoneGlobalInput, timezoneLocalInput, setValue]);

  React.useEffect(() => {
    setValue('timezone', initialTimezone);
  }, []);

  return (
    <div className={styles.timezoneInput}>
      <FormSelect
        name="timezoneGlobal"
        label="Timezone Region"
        options={timezoneGlobalOptions}
        defaultValue={initialGlobalArea}
        variant="outlined"
        rules={{
          required: {
            value: true,
            message: 'Timezone Region is required.',
          },
        }}
      />
      <FormSelect
        name="timezoneLocal"
        label="Timezone Area"
        options={timezoneLocalOptions}
        defaultValue={initialLocalArea}
        variant="outlined"
        rules={{
          required: {
            value: true,
            message: 'Timezone Area is required.',
          },
        }}
      />
    </div>
  );
}
