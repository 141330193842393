import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export default function Teams() {
  const { data, error, isLoading } = useApiData({
    path: '/administrator/teams',
    params: {
      includeTeamMemberCounts: true,
      includeGoalCounts: true,
    },
  });
  if (isLoading) {
    return <Loading message="Fetching teams..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <Table
      rows={data}
      topButtons={[
        {
          label: 'Create Team',
          variant: 'contained',
          startIcon: <GroupAddIcon />,
          to: '/administrator/teams/create',
        },
      ]}
      linkFunction={(id) => id}
      rowLinks={{
        label: {
          label: 'View Team',
          to: (id) => id,
        },
      }}
      title="Teams"
      columns={[
        {
          id: 'label',
          label: 'Label',
          numeric: false,
        },
        {
          id: 'teamMemberCount',
          label: 'Members',
          numeric: true,
        },
        {
          id: 'goalCount',
          label: 'Goals',
          numeric: true,
        },
      ]}
    />
  );
}
