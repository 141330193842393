import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import styles from './FormSelect.module.scss';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';

export default function FormSelect(props) {
  const {
    label,
    defaultHelperText,
    options,
    defaultValue,
    preserveHeight,
    variant,
    size,
    rules,
    multiple,
    disabled,
  } = props;
  const { control } = useFormContext();
  const initialDefaultValue = multiple ? [] : '';
  const useControllerProps = {
    ...props,
    control,
    defaultValue: defaultValue ? defaultValue : initialDefaultValue,
    rules,
  };

  const {
    field: { onChange, name, value },
    fieldState: { invalid, error },
  } = useController(useControllerProps);

  const helperId = `${name}-helper-text`;
  const labelId = `${name}-label`;
  const displayedDefaultHelperText =
    defaultHelperText || (preserveHeight ? ' ' : '');
  const helperTextMessage = error ? error.message : displayedDefaultHelperText;

  return (
    <div className={styles.select}>
      <FormControl variant={variant || 'outlined'}>
        {label && (
          <InputLabel error={invalid} id={labelId}>
            {label}
          </InputLabel>
        )}
        <Select
          aria-describedby={helperId}
          labelId={labelId}
          size={size || 'normal'}
          label={label}
          value={value}
          error={invalid}
          onChange={onChange}
          multiple={multiple}
          disabled={disabled}
          renderValue={(selected) => {
            if (multiple) {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        options.filter((o) => o.value === value)?.[0]?.label
                      }
                      variant="outlined"
                      color="primary"
                    />
                  ))}
                </Box>
              );
            }
            return options.filter((o) => o.value === value)?.[0]?.label;
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
              className={styles.option}
            >
              {multiple && (
                <Checkbox checked={value.indexOf(option.value) > -1} />
              )}
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error={invalid} id={helperId}>
          {helperTextMessage}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
