import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './EditTeamMembersForm.module.scss';
import useApiData from '../../../../../../../hooks/useApiData';
import Loading from '../../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../../Common/Error/Error';
import Table from '../../../../../Common/Table/Table/Table';

export default function EditTeamMembersForm() {
  const [isSettingUp, setIsSettingUp] = React.useState(true);
  const {
    getValues,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { teamId } = useParams();

  const {
    data: userData,
    isLoading: userIsLoading,
    error: userError,
  } = useApiData({
    path: `/user/users`,
  });

  const {
    data: teamData,
    isLoading: teamIsLoading,
    error: teamError,
  } = useApiData({
    path: `/user/teams/${teamId}`,
    params: {
      includeTeamMembers: true,
    },
  });

  React.useEffect(() => {
    if (teamData === null) {
      return;
    }
    register('cognitoIds', {
      validate: (fieldData) =>
        (Array.isArray(fieldData) && fieldData.length >= 1) ||
        'At least one member must be assigned to the team.',
      value: teamData?.teamMembers?.map((m) => m.cognitoId),
    });

    register('leadCognitoIds', {
      validate: (fieldData) =>
        (Array.isArray(fieldData) && fieldData.length >= 1) ||
        'At least one of the team members must be a team leader.',
      value: teamData?.teamMembers
        .filter((m) => m.isLead)
        .map((m) => m.cognitoId),
    });

    setIsSettingUp(false);
  }, [teamData]);

  const updateSelectedCognitoIds = (newSelected) => {
    setValue('cognitoIds', newSelected, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const updateSelectedLeadCognitoIds = React.useCallback(
    (newSelected) => {
      setValue('leadCognitoIds', newSelected, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  if (teamIsLoading) {
    return <Loading message="Fetching team..." />;
  }

  if (userIsLoading) {
    return <Loading message="Fetching users..." />;
  }

  if (isSettingUp) {
    return <Loading message="Loading congifuration..." />;
  }

  if (userError || userData === null) {
    return getErrorComponentFromHttpError(userError);
  }
  if (teamError || teamData === null) {
    return getErrorComponentFromHttpError(teamError);
  }

  return (
    <>
      <div className={styles.table}>
        <Table
          rows={userData}
          allowSort
          allowSelect
          defaultSelected={teamData.teamMembers.map((m) => m.cognitoId)}
          onSelected={(selectedIds) => {
            updateSelectedCognitoIds(selectedIds);
          }}
          allowPagination
          title="Members"
          keyColumn="cognitoId"
          columns={[
            {
              id: 'firstName',
              label: 'First Name',
              numeric: false,
            },
            {
              id: 'lastName',
              label: 'Last Name',
              numeric: false,
            },
            {
              id: 'isEnabled',
              label: 'Enabled',
              boolean: true,
            },
          ]}
        />
        <FormHelperText error={!!errors.cognitoIds}>
          {(errors.cognitoIds && errors.cognitoIds.message) || ''}
        </FormHelperText>
      </div>
      <div className={styles.table}>
        <Table
          rows={userData.filter((r) =>
            getValues('cognitoIds')?.includes(r.cognitoId),
          )}
          allowSort
          allowSelect
          defaultSelected={teamData.teamMembers
            .filter((m) => m.isLead)
            .map((m) => m.cognitoId)}
          onSelected={(selectedIds) =>
            updateSelectedLeadCognitoIds(selectedIds)
          }
          allowPagination
          title="Team Leaders"
          keyColumn="cognitoId"
          columns={[
            {
              id: 'firstName',
              label: 'First Name',
              numeric: false,
            },
            {
              id: 'lastName',
              label: 'Last Name',
              numeric: false,
            },
            {
              id: 'isEnabled',
              label: 'Enabled',
              boolean: true,
            },
          ]}
        />
        <FormHelperText error={!!errors.leadCognitoIds}>
          {(errors.leadCognitoIds && errors.leadCognitoIds.message) || ''}
        </FormHelperText>
      </div>
    </>
  );
}
