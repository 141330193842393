import React from 'react';
import layoutStyles from '../../../../_layouts/Layout/Layout.module.scss';
import styles from './GoalHierarchy.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import { TreeEditorProvider } from '../../../../../hooks/useTreeEditor';
import { AdministratorTreeEditor } from '../../../Common/TreeEditor/TreeEditor';
import Loading from '../../../Common/Loading/Loading';
import Error, {
  getErrorComponentFromHttpError,
} from '../../../Common/Error/Error';
import GoalHierarchyForm from './GoalHierarchyForm/GoalHierarchyForm';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from '@mui/material/Button';

export default function GoalHierarchy() {
  const wrapperRef = React.useRef();
  const {
    data: goalsData,
    error: goalsError,
    isLoading: goalsIsLoading,
    reload: goalsReload,
  } = useApiData({
    path: `/administrator/goals/hierarchy`,
    params: {
      includeTeams: true,
      includeGoalMeasures: true,
    },
  });
  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsIsLoading,
  } = useApiData({
    path: `/administrator/teams`,
    params: {
      includeTeams: true,
      includeTeamMembers: true,
    },
  });

  if (goalsIsLoading) {
    return <Loading message="Fetching goals..." />;
  }

  if (teamsIsLoading) {
    return <Loading message="Fetching teams..." />;
  }

  if (goalsError) {
    return getErrorComponentFromHttpError(goalsError);
  }

  if (teamsError || teamsData === null) {
    return getErrorComponentFromHttpError(teamsError);
  }

  if (goalsData === null) {
    return (
      <div className={styles.noData}>
        <Error
          icon={<SearchRoundedIcon fontSize="inherit" />}
          title="No Goals"
          text="It looks like there aren't any goals defined for your organisation. You will need to create the goal hierarchy first."
        />
        <Button
          component={Link}
          to={'edit'}
          variant="outlined"
          startIcon={<EditOutlinedIcon />}
        >
          Create Goal Hierarchy
        </Button>
      </div>
    );
  }

  return (
    <div className={layoutStyles.forceFullWidth}>
      <TreeEditorProvider
        initialData={goalsData}
        reload={goalsReload}
        teams={teamsData}
      >
        <div className={styles.form}>
          <GoalHierarchyForm />
        </div>
        <div className={styles.treeEditorWrapper} ref={wrapperRef}>
          <AdministratorTreeEditor wrapperRef={wrapperRef} variant="view" />
        </div>
      </TreeEditorProvider>
    </div>
  );
}
