import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './NavbarSubItem.module.scss';

export default function NavbarSubItem(props) {
  const { path, label, useNativeAElement } = props;
  const location = useLocation();
  const isActive = path && new RegExp(`${path}(/|$)$`).test(location.pathname);

  const LinkWrapper = (props) => {
    if (useNativeAElement) {
      return (
        <a href={path} className={isActive ? styles.activeItem : ''}>
          {props.children}
        </a>
      );
    }
    return (
      <Link to={path} className={isActive ? styles.activeItem : ''}>
        {props.children}
      </Link>
    );
  };

  return (
    <LinkWrapper>
      <div className={styles.navbarSubItem}>
        <p className={styles.label}>{label}</p>
      </div>
    </LinkWrapper>
  );
}
