import React from 'react';
import layoutStyles from '../../../../../_layouts/Layout/Layout.module.scss';
import styles from './EditGoalHierarchy.module.scss';
import { AdministratorTreeEditor } from '../../../../Common/TreeEditor/TreeEditor';
import { TreeEditorProvider } from '../../../../../../hooks/useTreeEditor';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import useApiData from '../../../../../../hooks/useApiData';
import EditGoalHierarchyForm from './EditGoalHierarchyForm/EditGoalHierarchyForm';

export default function EditGoalHierarchy() {
  const wrapperRef = React.useRef();
  const { data, error, isLoading, reload } = useApiData({
    path: `/administrator/goals/hierarchy`,
    params: {
      includeTeams: true,
      includeGoalMeasures: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching goals..." />;
  }

  if (error) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <div className={layoutStyles.forceFullWidth}>
      <TreeEditorProvider initialData={data} reload={reload}>
        <div className={styles.form}>
          <EditGoalHierarchyForm />
        </div>
        <div className={styles.treeEditorWrapper} ref={wrapperRef}>
          <AdministratorTreeEditor
            isEditable
            wrapperRef={wrapperRef}
            variant="form"
          />
        </div>
      </TreeEditorProvider>
    </div>
  );
}
