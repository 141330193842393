import React from 'react';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Link, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import styles from './Goal.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import ProgressBar from '../../../Common/ProgressBar/ProgressBar';
import DetailedGoalProgressChart from './DetailedGoalProgressChart/DetailedGoalProgressChart';
import { AdministratorLogMeasurementDialog } from '../../../Common/LogMeasurementDialog/LogMeasurementDialog';
import {
  getAbsoluteTimestamp,
  getRelativeTimestamp,
} from '../../../../../util/formatDate';

function getDurationProgress(start, end, now) {
  if (now > end) {
    return 1;
  }
  if (now < start) {
    return 0;
  }
  return (now - start) / (end - start);
}

export default function Goal() {
  const [isLogMeasurementDialogOpen, setIsLogMeasurementDialogOpen] =
    React.useState(false);
  const { goalId } = useParams();
  const {
    data: goalData,
    error: goalError,
    isLoading: goalIsLoading,
    reload: goalReload,
  } = useApiData({
    path: `/administrator/goals/${goalId}`,
    params: {
      includeTeams: true,
      includeGoalMeasures: true,
      includeDependencyAndRequiredForIds: true,
    },
  });

  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsIsLoading,
  } = useApiData({
    path: `/administrator/teams`,
    params: {
      includeTeams: true,
      includeTeamMembers: true,
    },
  });

  if (goalIsLoading) {
    return <Loading message="Fetching goal..." />;
  }

  if (teamsIsLoading) {
    return <Loading message="Fetching teams..." />;
  }

  if (goalError || goalData === null) {
    return getErrorComponentFromHttpError(goalError);
  }

  if (teamsError || teamsData === null) {
    return getErrorComponentFromHttpError(teamsError);
  }

  const {
    label,
    isTeamGoal,
    description,
    startValue,
    endValue,
    startTime,
    endTime,
    teams,
    goalMeasures,
  } = goalData;

  const currentValue =
    goalMeasures.length > 0
      ? goalMeasures[goalMeasures.length - 1].value
      : startValue;

  const startTimeMs = new Date(startTime).getTime();
  const endTimeMs = new Date(endTime).getTime();
  const nowMs = new Date().getTime();
  const timeProgress = getDurationProgress(startTimeMs, endTimeMs, nowMs);
  const goalProgress = (currentValue - startValue) / (endValue - startValue);
  const goalProgressDesiredValue = Math.floor(
    timeProgress * (endValue - startValue) + startValue,
  );
  const goalProgressDesired =
    (goalProgressDesiredValue - startValue) / (endValue - startValue);

  return (
    <div className={styles.goal}>
      <AdministratorLogMeasurementDialog
        goal={goalData}
        isOpen={isLogMeasurementDialogOpen}
        close={() => setIsLogMeasurementDialogOpen(false)}
        reload={goalReload}
      />
      <div className={styles.header}>
        <h1>{label}</h1>
        <div className={styles.row}>
          <Button
            variant="outlined"
            startIcon={<EditOutlinedIcon />}
            component={Link}
            to="/administrator/goals/hierarchy/edit"
          >
            Edit
          </Button>
        </div>
      </div>
      <div className={styles.mainRow}>
        <div className={styles.stack}>
          {(description && (
            <p className={styles.description}>{description}</p>
          )) || <p className={styles.noData}>No description for this goal</p>}
        </div>
        <div className={styles.stack}>
          <Tooltip
            title={`Current: ${currentValue}, optimal: ${goalProgressDesiredValue}`}
            className={styles.stack}
          >
            <div className={styles.charts}>
              <p className={styles.label}>Goal Progress:</p>
              <ProgressBar
                value={goalProgress}
                color="linear-gradient(90deg, rgb(158 212 131), rgb(146 234 178))"
                trailingDot
                trailingDotColor="rgb(105 184 105)"
              />

              <ProgressBar
                value={goalProgressDesired}
                color="linear-gradient(90deg, rgb(127 151 230), rgb(176 221 254))"
                trailingDot
                trailingDotColor="rgb(103 155 219)"
              />
              <div className={styles.row}>
                <p className={styles.value}>{startValue}</p>
                <p className={styles.value}>{endValue}</p>
              </div>
            </div>
          </Tooltip>
          <p className={styles.label}>Time:</p>
          <ProgressBar
            value={timeProgress}
            color="linear-gradient(90deg, rgb(212 141 186), rgb(214 183 255)"
            trailingDot
            trailingDotColor="rgb(170 124 232)"
          />
          <div className={styles.row}>
            <p className={styles.value}>
              {getAbsoluteTimestamp(new Date(startTime))}
            </p>
            <p className={styles.value}>
              {getAbsoluteTimestamp(new Date(endTime))}
            </p>
          </div>
        </div>
        <div className={styles.stack}>
          <p className={styles.label}>Team{teams.length > 0 && 's'}:</p>
          {(teams.length === 0 && (
            <div className={styles.row}>
              <p className={styles.noData}>Not assigned</p>
              {!isTeamGoal && (
                <Button
                  size="small"
                  startIcon={<GroupAddOutlinedIcon />}
                  component={Link}
                  to={'/administrator/goals/hierarchy'}
                  className={styles.editButton}
                >
                  Assign Team
                </Button>
              )}
            </div>
          )) || (
            <div className={styles.row}>
              <p className={styles.teams}>
                {teams.map((t, i, a) => (
                  <React.Fragment key={`goal-${goalId}${t.id}`}>
                    <Link to={`/administrator/teams/${t.id}`}>
                      <span className={styles.teamName}>{t.label}</span>
                    </Link>
                    {i !== a.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </p>
              {!isTeamGoal && (
                <Button
                  size="small"
                  startIcon={<GroupAddOutlinedIcon />}
                  component={Link}
                  to={'/administrator/goals/hierarchy'}
                >
                  Edit
                </Button>
              )}
            </div>
          )}
          <p className={styles.label}>Latest measurement:</p>
          {(goalMeasures.length === 0 && (
            <p className={styles.noData}>Not recorded</p>
          )) || (
            <p className={styles.value}>
              {goalMeasures[goalMeasures.length - 1].value}{' '}
              <span className={styles.valueSecondary}>
                {getAbsoluteTimestamp(
                  new Date(goalMeasures[goalMeasures.length - 1].time),
                )}
              </span>
              <span className={styles.valueSecondary}>
                (
                {getRelativeTimestamp(
                  new Date(goalMeasures[goalMeasures.length - 1].time),
                )}
                )
              </span>
            </p>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={() => setIsLogMeasurementDialogOpen(true)}
            startIcon={<AddchartOutlinedIcon />}
          >
            Log Measurement
          </Button>
        </div>
      </div>

      <div className={styles.chart}>
        <DetailedGoalProgressChart
          label={label}
          startValue={startValue}
          endValue={endValue}
          startTime={startTime}
          endTime={endTime}
          goalMeasures={goalMeasures}
        />
      </div>
    </div>
  );
}
