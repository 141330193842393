import React from 'react';
import styles from './FormGoalNode.module.scss';
import useTreeEditor from '../../../../../../hooks/useTreeEditor';
import FormGoalNodeForm from './FormGoalNodeForm/FormGoalNodeForm';
import Menu from '../../../Menu/Menu';

export default function FormGoalNode(props) {
  const { nodeDatum, isEditable } = props;
  const {
    attributes: {
      id,
      label,
      description,
      startValue,
      endValue,
      startTime,
      endTime,
      isRoot,
      isTeamGoal,
    },
  } = nodeDatum;
  const { addNode, removeNode } = useTreeEditor();

  const menuOptions = [
    {
      label: `Add sub-goal`,
      handler: () => addNode(id),
    },
    {
      label: `Remove this goal`,
      handler: () => {
        removeNode(id);
      },
      disabled: isRoot || !isEditable,
    },
  ];

  return (
    <div className={styles.formGoalNode}>
      <div className={styles.topRow}>
        {isTeamGoal && <p className={styles.teamGoalIndicator}>Team Goal</p>}
        <Menu id={`${id}-menu`} items={menuOptions} />
      </div>
      <div
        className={`${styles.innerWrapper}
         ${isRoot ? styles.root : ''}`}
      >
        <FormGoalNodeForm
          id={id}
          label={label}
          description={description}
          startValue={startValue}
          endValue={endValue}
          startTime={startTime}
          endTime={endTime}
          isEditable={isEditable}
        />
      </div>
    </div>
  );
}
