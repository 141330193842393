import React from 'react';
import styles from './GroupNode.module.scss';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';

export default function GroupNode(props) {
  const { nodeDatum } = props;
  const {
    attributes: { label },
  } = nodeDatum;

  return (
    <div className={styles.groupNode}>
      <div className={styles.innerWrapper}>
        <div className={styles.icon}>
          <WorkspacesOutlinedIcon fontSize="inherit" />
        </div>
        <h2>{label}</h2>
      </div>
    </div>
  );
}
