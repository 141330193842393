import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './EditTeamMembers.module.scss';
import Form from '../../../../Common/Form/Form';
import APIClient from '../../../../../../util/APIClient';
import FormAlert from '../../../../Common/FormAlert/FormAlert';
import EditTeamMembersForm from './EditTeamMembersForm/EditTeamMembersForm';

export default function EditTeamMembers() {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  async function onSubmit(data) {
    setIsSubmitting(true);
    const team = {
      cognitoIds: data.cognitoIds,
      leadCognitoIds: data.leadCognitoIds,
    };

    try {
      await APIClient.put(`/user/teams/${teamId}`, team);
      setHasSubmitted(true);
      setFormAlert({
        severity: 'success',
        title: 'Team updated successfully!',
        message:
          'Your team has been updated successfully. You will be redirected shortly.',
      });
      setTimeout(() => {
        navigate(`/user/teams/${teamId}`);
      }, 3000);
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not update team!',
        message:
          'Something went wrong during team update. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.editTeam}>
      <h1>Edit team</h1>
      <p className={styles.description}>
        Edit a team using the form below. After your changes, at least one team
        member and one team leader must remain. If instead you would like to
        remove the team entirely, you can do so from the{' '}
        <Link to="/teams">Teams</Link> page by selecting the team you wish to
        remove and pressing appropriate icon button.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <EditTeamMembersForm />
        <div className={styles.formControls}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={hasSubmitted}
            fullWidth
          >
            <span>Save Changes</span>
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </Form>
    </div>
  );
}
