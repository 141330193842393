import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './Meeting.module.scss';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import MeetingHeader from './MeetingHeader/MeetingHeader';
import { getAbsoluteTimestamp } from '../../../../../../util/formatDate';
import { MeetingProvider } from '../../../../../../hooks/useMeeting';
import MeetingCommitments from './MeetingCommitments/MeetingCommitments';
import AttendanceDialog from './AttendanceDialog/AttendanceDialog';

const MeetingTeamGoalsContext = React.createContext(null);

export function useMeetingTeamGoals() {
  return React.useContext(MeetingTeamGoalsContext);
}

export default function Meeting() {
  const { teamId } = useParams();

  const { data, error, isLoading } = useApiData({
    path: `/user/teams/${teamId}`,
    params: {
      includeGoals: true,
    },
  });
  if (isLoading) {
    return <Loading message="Joining meeting..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <MeetingProvider>
      <div className={styles.meeting}>
        <AttendanceDialog />
        <MeetingHeader
          title={`${data.label} meeting (${getAbsoluteTimestamp(new Date())})`}
        />
        <MeetingTeamGoalsContext.Provider value={data.goals}>
          <MeetingCommitments />
        </MeetingTeamGoalsContext.Provider>
      </div>
    </MeetingProvider>
  );
}
