import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Common/Table/Table/Table';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import APIClient from '../../../../../util/APIClient';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export default function AllUsers() {
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const { data, error, isLoading, reload } = useApiData({
    path: '/administrator/users',
  });

  const disableUsers = React.useCallback(async () => {
    await APIClient.patch('/administrator/users', {
      cognitoIds: selectedUsers,
      operation: 'disable',
    });
    reload();
  }, [selectedUsers]);

  const enableUsers = React.useCallback(async () => {
    await APIClient.patch('/administrator/users', {
      cognitoIds: selectedUsers,
      operation: 'enable',
    });
    reload();
  }, [selectedUsers]);

  if (isLoading) {
    return <Loading message="Fetching users..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <Table
      rows={data}
      title="Users"
      keyColumn="cognitoId"
      linkFunctionColumn="cognitoId"
      topButtons={[
        {
          label: 'Invite User',
          variant: 'contained',
          startIcon: <PersonAddIcon />,
          to: '/administrator/users/invite',
        },
      ]}
      linkFunction={(id) => `/administrator/users/${id}`}
      allowSort
      allowSelect
      onSelected={(selected) => setSelectedUsers(selected)}
      selectedActions={[
        {
          handler: enableUsers,
          label: 'Enable users',
          icon: <LockOpenIcon />,
        },
        {
          handler: disableUsers,
          label: 'Disable users',
          icon: <LockIcon />,
        },
      ]}
      columns={[
        {
          id: 'firstName',
          label: 'First Name',
          numeric: false,
        },
        {
          id: 'lastName',
          label: 'Last Name',
          numeric: false,
        },
        {
          id: 'email',
          label: 'Email Address',
          numeric: false,
        },
        {
          id: 'isEnabled',
          label: 'Enabled',
          boolean: true,
        },
      ]}
    />
  );
}
