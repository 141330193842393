import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

/**
 * Only allows the component to be displayed if the user is authenticated and an administrator. This
 * means that unauthenticated users, or authenticated users who are not administrators will NOT be
 * allowed to see the contents.
 * @param Component component to be wrapped in Authenticated Admin Route.
 * @returns {(function(*): (*|null))|*} Component if the user is authenticated and an administrator,
 * otherwise a redirect to '/signIn' (for unauthenticated users) or '/' (for non-administrators).
 */
export default function AuthenticatedUserRoute(Component) {
  return function WrappedAuthenticatedRoute(props) {
    const { isSignedIn, isUser } = useAuth();

    if (isSignedIn && isUser) {
       
      return <Component {...props} />;
    }
    return <Navigate to="/" replace />;
  };
}
