import React from 'react';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import styles from './GoalHierarchyForm.module.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useAuth from '../../../../../../../hooks/useAuth';

export default function GoalHierarchyForm() {
  const { teamId } = useParams();
  const { currentUser } = useAuth();
  const isLead = currentUser.teams.filter((t) => t.id === teamId)?.[0]?.isLead;
  return (
    <div className={styles.goalHierarchyForm}>
      {isLead && (
        <Button
          component={Link}
          to={'edit'}
          variant="outlined"
          startIcon={<EditOutlinedIcon />}
        >
          Edit Goal Hierarchy
        </Button>
      )}
    </div>
  );
}
