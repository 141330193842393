import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Instructions from '../Instructions/HelpInstructions/Instructions';

export default function HelpLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="instructions" />} />
      <Route path="instructions" element={<Instructions />} />
    </Routes>
  );
}
