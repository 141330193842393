import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import RuleIcon from '@mui/icons-material/Rule';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import styles from './Navbar.module.scss';
import NavbarItem from './NavbarItem/NavbarItem';
import NavbarSubItem from './NavbarSubItem/NavbarSubItem';
import useAuth from '../../../../hooks/useAuth';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';

export default function Navbar() {
  const { isUser, isAdministrator, currentUser } = useAuth();

  return (
    <div className={styles.navbar}>
      {isAdministrator && (
        <>
          {isAdministrator && isUser && (
            <p className={styles.groupHeader}>Administrator</p>
          )}
          <NavbarItem
            path="/administrator/goals"
            icon={<RuleIcon />}
            label="Goals"
          >
            <NavbarSubItem path="/administrator/goals" label="List" />
            <NavbarSubItem
              path="/administrator/goals/hierarchy"
              label="Hierarchy"
            />
          </NavbarItem>
          <NavbarItem
            path="/administrator/teams"
            icon={<GroupsIcon />}
            label="Teams"
          >
            <NavbarSubItem path="/administrator/teams" label="All Teams" />
          </NavbarItem>
          <NavbarItem
            path="/administrator/users"
            icon={<PersonIcon />}
            label="Users"
          >
            <NavbarSubItem path="/administrator/users" label="All Users" />
            <NavbarSubItem
              path="/administrator/users/administrators"
              label="Administrators"
            />
            <NavbarSubItem path="/administrator/users/users" label="Users" />
            <NavbarSubItem
              path="/administrator/users/invite"
              label="Invite Users"
            />
          </NavbarItem>
        </>
      )}
      {isAdministrator && isUser && <hr />}
      {isUser && (
        <>
          {isUser && isAdministrator && (
            <p className={styles.groupHeader}>User</p>
          )}
          {currentUser.teams.map((t) => (
            <NavbarItem
              path={`/user/teams/${t.id}`}
              icon={<WorkspacesOutlinedIcon />}
              label={t.label}
              key={t.id}
            >
              {/*<NavbarSubItem path={`/user/teams/${t.id}`} label="Overview" />*/}
              <NavbarSubItem
                path={`/user/teams/${t.id}/meetings`}
                label="Meetings"
              />
              <NavbarSubItem path={`/user/teams/${t.id}/goals`} label="Goals" />
              <NavbarSubItem
                path={`/user/teams/${t.id}/teamMembers`}
                label="Team Members"
              />
              {/*<NavbarSubItem*/}
              {/*  path={`/user/teams/${t.id}/commitments`}*/}
              {/*  label="Commitments"*/}
              {/*/>*/}
              {t.isLead && (
                <NavbarSubItem
                  path={`/user/teams/${t.id}/configuration`}
                  label="Configuration"
                />
              )}
            </NavbarItem>
          ))}
          <NavbarItem
            path="/user/commitments"
            icon={<RuleIcon />}
            label="Commitments"
          >
            <NavbarSubItem
              path="/user/commitments/current"
              label="Current Commitments"
            />
            <NavbarSubItem
              path="/user/commitments/all"
              label="All Commitments"
            />
          </NavbarItem>
        </>
      )}
      <NavbarItem path="/help" icon={<HelpOutlineOutlinedIcon />} label="Help">
        <NavbarSubItem
          path="/help/instructions#signup_signin"
          label="Sign-up and Sign-In"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#password_reset"
          label="Password Reset"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#goals"
          label="Goals"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#meetings_overview"
          label="Meetings"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#attending_meeting"
          label="Attending a Meeting"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#meeting_controls"
          label="Meeting Controls"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#team_members"
          label="Team Members"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#team_configuration"
          label="Team Configuration"
          useNativeAElement
        />
        <NavbarSubItem
          path="/help/instructions#commitments"
          label="Commitments"
          useNativeAElement
        />
      </NavbarItem>
      <div className={styles.fillMax} />
      <NavbarItem
        path="/support"
        icon={<GppMaybeOutlinedIcon />}
        label="Support"
      >
        <NavbarSubItem path="/support/contact" label="Contact" />
        <NavbarSubItem path="/support/privacy" label="Privacy Policy" />
        <NavbarSubItem path="/support/terms" label="Terms of Use" />
      </NavbarItem>
    </div>
  );
}
