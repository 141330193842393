import React from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './CompleteManualSignUp.module.scss';
import FormAlert from '../../Common/FormAlert/FormAlert';
import FormTextField from '../../Common/FormTextField/FormTextField';
import Form from '../../Common/Form/Form';

export function getFormAlertFromCognitoError(error) {
  switch (error.message) {
    case 'User cannot be confirmed. Current status is CONFIRMED':
      return {
        severity: 'info',
        title: 'User already confirmed!',
        message:
          'This user account is already confirmed. You should now be able to sign in.',
      };
    case 'Username/client id combination not found.':
      return {
        severity: 'error',
        title: 'User not found!',
        message:
          'The user account with the provided details does not exist. Please ensure you have entered the details correctly. If the problem persists, please contact the administrator for further support.',
      };
    case 'Invalid verification code provided, please try again.':
      return {
        severity: 'error',
        title: 'Invalid verification code!',
        message:
          'The provided verification code was invalid. Please ensure you have entered the details correctly. If the problem persists, please contact the administrator for further support.',
      };
    case 'Incorrect username or password.':
      return {
        severity: 'error',
        title: 'Incorrect e-mail or password!',
        message:
          'The provided e-mail and password combination was invalid. Please ensure you have entered the details correctly. If the problem persists, please contact the administrator for further support.',
      };
    default:
      return {
        severity: 'error',
        title: 'Could not complete sign up!',
        message:
          'Something went wrong during sign-up. Please contact the administrator for further support.',
      };
  }
}

export default function CompleteManualSignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formAlert, setFormAlert] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  const firstName = searchParams.get('firstName');
  const lastName = searchParams.get('lastName');
  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const onSubmit = React.useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await Auth.confirmSignUp(data.email, data.code, {
          clientMetadata: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
        }).then(() => {
          setFormAlert({
            severity: 'success',
            title: 'Account confirmed!',
            message:
              'Your account has been confirmed! You will be redirected shortly.',
          });
          setHasSubmitted(true);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        });
      } catch (error) {
        const formAlert = getFormAlertFromCognitoError(error);
        if (formAlert.severity === 'info') {
          setHasSubmitted(true);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
        setFormAlert(formAlert);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate],
  );

  React.useEffect(() => {
    if (email && code && firstName && lastName) {
      onSubmit({ email, code, firstName, lastName });
    }
  }, [email, code, firstName, lastName, onSubmit]);

  return (
    <div className={styles.completeManualSignUp}>
      <p className={styles.description}>
        Complete your registration and account using the form below.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <div className={styles.horizontalInputs}>
          <FormTextField
            name="firstName"
            label="First name"
            autocomplete="given-name"
            defaultValue={firstName}
            rules={{
              required: { value: true, message: 'First name is required.' },
            }}
          />
          <FormTextField
            name="lastName"
            label="Last name"
            autocomplete="family-name"
            defaultValue={lastName}
            rules={{
              required: { value: true, message: 'Last name is required.' },
            }}
          />
        </div>
        <FormTextField
          name="email"
          label="E-mail address"
          autocomplete="email"
          defaultValue={email}
          rules={{
            required: { value: true, message: 'E-mail address is required.' },
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'E-mail address must be a valid e-mail.',
            },
          }}
        />
        <FormTextField
          name="code"
          label="Verification code"
          autocomplete="off"
          defaultValue={code}
          rules={{
            required: {
              value: true,
              message: 'Verification code is required.',
            },
          }}
        />
        <div className={styles.formControls}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={hasSubmitted}
            fullWidth
          >
            Complete Sign Up
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </Form>
    </div>
  );
}
