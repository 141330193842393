import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './CreateTeam.module.scss';
import Form from '../../../Common/Form/Form';
import CreateTeamForm from './CreateTeamForm/CreateTeamForm';
import APIClient from '../../../../../util/APIClient';
import FormAlert from '../../../Common/FormAlert/FormAlert';

export default function CreateTeam() {
  const navigate = useNavigate();
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  async function onSubmit(data) {
    setIsSubmitting(true);
    const team = {
      label: data.label,
      description: data.description || null,
      cognitoIds: data.cognitoIds,
      leadCognitoIds: data.leadCognitoIds,
    };

    try {
      const response = await APIClient.post('/administrator/teams', team);
      setHasSubmitted(true);
      setFormAlert({
        severity: 'success',
        title: 'Team created successfully!',
        message:
          'Your team has been created successfully. You will be redirected shortly.',
      });
      setTimeout(() => {
        navigate(`/administrator/teams/${response.data.id}`);
      }, 3000);
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not create team!',
        message:
          'Something went wrong during team creation. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.createTeam}>
      <h1>Create a new team</h1>
      <p className={styles.description}>
        Create a new team using the form below. You need to select at least one
        member and at least one team leader.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <CreateTeamForm />
        <div className={styles.formControls}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={hasSubmitted}
            fullWidth
          >
            <span>Create</span>
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </Form>
    </div>
  );
}
