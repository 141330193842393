import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './CreateTeamForm.module.scss';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import Table from '../../../../Common/Table/Table/Table';
import FormTextField from '../../../../Common/FormTextField/FormTextField';

export default function CreateTeamForm() {
  const {
    getValues,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const { data, isLoading, error } = useApiData({
    path: `/administrator/users`,
    params: {
      group: 'users',
      includeTeamCounts: true,
    },
  });

  register('cognitoIds', {
    validate: (fieldData) =>
      (Array.isArray(fieldData) && fieldData.length >= 1) ||
      'At least one member must be assigned to the team.',
  });

  register('leadCognitoIds', {
    validate: (fieldData) =>
      (Array.isArray(fieldData) && fieldData.length >= 1) ||
      'At least one of the team members must be a team leader.',
  });

  const updateSelectedCognitoIds = (newSelected) => {
    setValue('cognitoIds', newSelected, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const updateSelectedLeadCognitoIds = React.useCallback(
    (newSelected) => {
      setValue('leadCognitoIds', newSelected, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  if (isLoading) {
    return <Loading message="Fetching users..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <>
      <div className={styles.fields}>
        <FormTextField
          name="label"
          label="Label"
          defaultHelperText="Label for this team."
          rules={{
            required: { value: true, message: 'Label is required.' },
          }}
        />
        <FormTextField
          name="description"
          label="Description"
          defaultHelperText="(Optional) Brief description of the role or purpose of this team."
        />
      </div>
      <div className={styles.table}>
        <Table
          rows={data}
          allowSort
          allowSelect
          onSelected={(selectedIds) => {
            updateSelectedCognitoIds(selectedIds);
          }}
          allowPagination
          title="Members"
          keyColumn="cognitoId"
          columns={[
            {
              id: 'firstName',
              label: 'First Name',
              numeric: false,
            },
            {
              id: 'lastName',
              label: 'Last Name',
              numeric: false,
            },
            {
              id: 'teamCount',
              label: 'Assigned to teams',
              numeric: true,
            },
            {
              id: 'isEnabled',
              label: 'Enabled',
              boolean: true,
            },
          ]}
        />
        <FormHelperText error={!!errors.cognitoIds}>
          {(errors.cognitoIds && errors.cognitoIds.message) || ''}
        </FormHelperText>
      </div>
      <div className={styles.table}>
        <Table
          rows={data.filter((r) =>
            getValues('cognitoIds')?.includes(r.cognitoId),
          )}
          allowSort
          allowSelect
          onSelected={(selectedIds) =>
            updateSelectedLeadCognitoIds(selectedIds)
          }
          allowPagination
          title="Team Leaders"
          keyColumn="cognitoId"
          columns={[
            {
              id: 'firstName',
              label: 'First Name',
              numeric: false,
            },
            {
              id: 'lastName',
              label: 'Last Name',
              numeric: false,
            },
            {
              id: 'teamCount',
              label: 'Assigned to teams',
              numeric: true,
            },
            {
              id: 'isEnabled',
              label: 'Enabled',
              boolean: true,
            },
          ]}
        />
        <FormHelperText error={!!errors.leadCognitoIds}>
          {(errors.leadCognitoIds && errors.leadCognitoIds.message) || ''}
        </FormHelperText>
      </div>
    </>
  );
}
