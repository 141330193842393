import React from 'react';
import layoutStyles from '../../../../../../_layouts/Layout/Layout.module.scss';
import styles from './EditGoalHierarchy.module.scss';
import { TeamTreeEditor } from '../../../../../Common/TreeEditor/TreeEditor';
import { TreeEditorProvider } from '../../../../../../../hooks/useTreeEditor';
import Loading from '../../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../../Common/Error/Error';
import useApiData from '../../../../../../../hooks/useApiData';
import EditGoalHierarchyForm from './EditGoalHierarchyForm/EditGoalHierarchyForm';
import { useParams } from 'react-router-dom';
import useAuth from '../../../../../../../hooks/useAuth';

export default function EditGoalHierarchy() {
  const { teamId } = useParams();
  const { currentUser } = useAuth();
  const wrapperRef = React.useRef();
  const teamName =
    currentUser.teams.filter((t) => t.id === teamId)?.[0]?.label || 'Team';
  const { data, error, isLoading, reload } = useApiData({
    path: `/user/teams/${teamId}/goals/hierarchy`,
    params: {
      includeTeams: true,
      includeGoalMeasures: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching goals..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  return (
    <div className={layoutStyles.forceFullWidth}>
      <TreeEditorProvider
        initialData={data}
        reload={reload}
        id={teamId}
        label={teamName}
        isTeamEditor={true}
      >
        <div className={styles.form}>
          <EditGoalHierarchyForm />
        </div>
        <div className={styles.treeEditorWrapper} ref={wrapperRef}>
          <TeamTreeEditor isEditable wrapperRef={wrapperRef} variant="form" />
        </div>
      </TreeEditorProvider>
    </div>
  );
}
