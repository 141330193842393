import React from 'react';
import styles from './InstructionsSection.module.scss';

export default function InstructionsSection(props) {
  const { children, title, id } = props;

  return (
    <div className={styles.instructionsSection}>
      <div className={styles.anchor} id={id} />
      <h1>{title}</h1>
      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
}
