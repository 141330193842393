import styles from './MeetingHeader.module.scss';
import ConnectionIndicator from '../ConnectionIndicator/ConnectionIndicator';
import ConnectedUsersIndicator from '../ConnectedUsersIndicator/ConnectedUsersIndicator';
import Button from '@mui/material/Button';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import React from 'react';
import {
  useIsTeamLeader,
  useIsLocked,
  useMeetingControls,
  useIsStale,
  useAttendanceDialog,
  useHasMeetingEnded,
} from '../../../../../../../hooks/useMeeting';
import LoadingButton from '@mui/lab/LoadingButton';

export default function MeetingHeader(props) {
  const { title } = props;
  const isTeamLeader = useIsTeamLeader();
  const isLocked = useIsLocked();
  const isStale = useIsStale();
  const { changeLock, endMeeting } = useMeetingControls();
  const hasMeetingEnded = useHasMeetingEnded();
  const { openAttendanceDialog } = useAttendanceDialog();

  const handleEndMeeting = React.useCallback(() => {
    endMeeting();
  }, [endMeeting]);

  return (
    <div className={styles.header}>
      <div className={styles.stack}>
        <h1>{title}</h1>
        <ConnectionIndicator />
      </div>
      <div className={styles.rightStack}>
        <div className={styles.row}>
          <ConnectedUsersIndicator />
        </div>
        {isTeamLeader && (
          <div className={styles.row}>
            <Button
              variant="outlined"
              startIcon={
                isLocked ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />
              }
              onClick={() => changeLock(!isLocked)}
              disabled={isStale}
            >
              {isLocked ? 'Unlock' : 'Lock'}
            </Button>

            <Button
              variant="outlined"
              startIcon={<HowToRegOutlinedIcon />}
              disabled={isStale}
              onClick={openAttendanceDialog}
            >
              Check Attendance
            </Button>

            <LoadingButton
              variant={isLocked ? 'contained' : 'outlined'}
              color="error"
              startIcon={<EventAvailableOutlinedIcon />}
              disabled={isStale || !isLocked}
              onClick={handleEndMeeting}
              loading={hasMeetingEnded}
            >
              End Meeting
            </LoadingButton>
          </div>
        )}
      </div>
    </div>
  );
}
