import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import { Link } from 'react-router-dom';

import styles from './TreeNodeTeamAssignmentDialog.module.scss';
import APIClient from '../../../../util/APIClient';
import FormAlert from '../FormAlert/FormAlert';
import useTreeEditor from '../../../../hooks/useTreeEditor';

export default React.memo(function TreeNodeTeamAssignmentDialog({
  goal,
  teams,
  isOpen,
  close,
  reload = () => {},
}) {
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { getGoalById } = useTreeEditor();

  const requiredForGoals = goal.requiredForIds.map((rfid) => {
    const goal = getGoalById(rfid).node.attributes;
    return {
      ...goal,
      teamIds: goal.teams.map((t) => t.id),
    };
  });

  const goalTeamIds = goal.teams.map((t) => t.id);
  const assignedTeams = teams
    .filter((t) => goalTeamIds.includes(t.id))
    .map((t) => ({
      ...t,
      hasAssignedParent:
        requiredForGoals.filter((goal) => goal.teamIds.includes(t.id)).length >
        0,
    }));
  const unassignedTeams = teams.filter((t) => !goalTeamIds.includes(t.id));

  const handleClose = () => {
    setFormAlert(null);
    setIsSubmitting(false);
    close();
  };

  const handleSubmit = async (teamId, unassign) => {
    setIsSubmitting(true);
    try {
      const url = `/administrator/teams/${teamId}/goals/${goal.id}`;
      if (unassign) {
        await APIClient.delete(url);
      } else {
        await APIClient.post(url);
      }
      reload();
      handleClose();
    } catch (e) {
      if (e?.response?.status === 409 && unassign) {
        setFormAlert({
          severity: 'error',
          title: `Could not unassign team!`,
          message: `This goal is a dependency of a goal that is assigned to a team you wished to unassign. Team can only be unassigned from a goal if that goal is not a dependency of a goal assigned to the same team. To unassign this team from this goal, first unassign it from the goal higher up in hierarchy.`,
        });
      } else {
        setFormAlert({
          severity: 'error',
          title: `Could not ${unassign && 'un'}assign team!`,
          message: `Something went wrong when ${unassign && 'un'}assigning the team. Please contact the administrator for further support.`,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFocusLost = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleFocusLost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Edit team assignment
      </DialogTitle>
      <div className={styles.content}>
        {(teams.length === 0 && (
          <>
            <DialogContentText id="alert-dialog-description">
              You have not created any teams yet. Before assigning the team to a
              goal, you will need to create at least one team.
            </DialogContentText>
            <div className={styles.createTeamButtonWrapper}>
              <Button
                component={Link}
                to="/administrator/teams/create"
                startIcon={<GroupAddOutlinedIcon />}
              >
                Create team
              </Button>
            </div>
          </>
        )) || (
          <>
            <DialogContentText id="alert-dialog-description">
              You are about to edit the team assignments for goal
              <span style={{ fontStyle: 'italic', paddingLeft: '.2rem' }}>
                {goal.label}
              </span>
              . Assigning a teams to a goal will allow them to work on and
              create sub-goals for that goal.
              {goal.dependencyIds.length > 0 && (
                <FormAlert
                  alert={{
                    title: 'Caution!',
                    message: `This goal has ${goal.dependencyIds.length} sub-goal${goal.dependencyIds.length > 1 ? 's' : ''}. Assigning it to a team will also assign all of the sub-goals to that team!`,
                    severity: 'warning',
                  }}
                />
              )}
            </DialogContentText>
            {assignedTeams.length > 0 && (
              <div className={styles.teams}>
                <p className={styles.label}>Assigned Teams:</p>
                <div className={styles.teamsEntries}>
                  {assignedTeams.map((t) => (
                    <React.Fragment key={t.id}>
                      <p>{t.label}</p>
                      <p>
                        {t.teamMembers
                          .map((tm) => `${tm.firstName} ${tm.lastName}`)
                          .join(', ')}
                      </p>
                      <div>
                        <LoadingButton
                          loading={isSubmitting}
                          onClick={() => handleSubmit(t.id, true)}
                          startIcon={<GroupRemoveOutlinedIcon />}
                          disabled={t.hasAssignedParent}
                        >
                          Unassign
                        </LoadingButton>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
            {unassignedTeams.length > 0 && (
              <div className={styles.teams}>
                <p className={styles.label}>Teams:</p>
                <div className={styles.teamsEntries}>
                  {unassignedTeams.map((t) => (
                    <React.Fragment key={t.id}>
                      <p>{t.label}</p>
                      <p>
                        {t.teamMembers
                          .map((tm) => `${tm.firstName} ${tm.lastName}`)
                          .join(', ')}
                      </p>
                      <LoadingButton
                        loading={isSubmitting}
                        onClick={() => handleSubmit(t.id)}
                        startIcon={<GroupAddOutlinedIcon />}
                      >
                        Assign
                      </LoadingButton>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        <div className={styles.buttons}>
          <LoadingButton
            loading={isSubmitting}
            color="primary"
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            Done
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </div>
    </Dialog>
  );
});
