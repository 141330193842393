import React from 'react';
import Tree from 'react-d3-tree';
import styles from './TreeEditor.module.scss';
import TreeEditorConfig from './TreeEditorConfig';
import CustomNode from './Nodes/CustomNode/CustomNode';
import useTreeEditor from '../../../../hooks/useTreeEditor';
import { useParams } from 'react-router-dom';

function getTranslation(wrapperRef, depth, variant) {
  const spacing =
    TreeEditorConfig[variant].depthFactor -
    TreeEditorConfig[variant].nodeSize.x;
  const elementWidth = wrapperRef.current.offsetWidth;
  const elementHeight = wrapperRef.current.offsetHeight;
  const nodeWidth = TreeEditorConfig[variant].nodeSize.x;
  const translation = {
    x: spacing + nodeWidth / 2,
    y: elementHeight / 2,
  };
  const treeWidth = depth * nodeWidth + (depth - 1) * spacing;

  if (elementWidth > treeWidth) {
    translation.x = (nodeWidth + elementWidth - treeWidth) / 2;
  }

  return translation;
}

function TreeEditor(props) {
  const { isEditable, wrapperRef, variant, teamId } = props;
  const treeEditorContext = useTreeEditor();
  const { data, getDepth } = treeEditorContext;
  const treeRef = React.useRef();

  if (!wrapperRef.current || !data) {
    return '';
  }

  return (
    <Tree
      ref={treeRef}
      data={{ ...data }}
      translate={getTranslation(wrapperRef, getDepth(), variant)}
      draggable
      pathFunc="step"
      nodeSize={{
        x: TreeEditorConfig[variant].nodeSize.x,
        y: TreeEditorConfig[variant].nodeSize.y,
      }}
      rootNodeClassName={styles.nodeOverride}
      branchNodeClassName={styles.nodeOverride}
      leafNodeClassName={styles.nodeOverride}
      svgClassName={styles.svgOverride}
      hasInteractiveNodes={isEditable}
      depthFactor={TreeEditorConfig[variant].depthFactor}
      separation={TreeEditorConfig[variant].separation}
      renderCustomNodeElement={({ nodeDatum }) => (
        <CustomNode
          nodeDatum={nodeDatum}
          isEditable={isEditable}
          variant={variant}
          teamId={teamId}
        />
      )}
    />
  );
}

export function AdministratorTreeEditor(props) {
  return <TreeEditor {...props} />;
}

/**
 * Must be placed within a path that contains the teamId parameter.
 */
export function TeamTreeEditor(props) {
  const { teamId } = useParams();
  return <TreeEditor {...props} teamId={teamId} />;
}
