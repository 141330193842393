import React from 'react';
import useDidMount from './useDidMount';

export default function usePostInitDebouncing(fn, deps, timeoutMs = 1000) {
  const didMount = useDidMount();

  React.useEffect(() => {
    if (didMount) {
      const timeout = setTimeout(() => {
        fn();
      }, timeoutMs);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, deps);
}
