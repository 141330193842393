import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import NotFound from '../../Common/NotFound/NotFound';
import AuthenticatedAdminRoute from '../../../_functional/AuthenticatedAdminRoute';
import Administrators from '../Users/Administrators/Administrators';
import AllUsers from '../Users/AllUsers/AllUsers';
import Users from '../Users/Users/Users';
import InviteUser from '../Users/InviteUser/InviteUser';
import Teams from '../Teams/Teams/Teams';
import CreateTeam from '../Teams/CreateTeam/CreateTeam';
import Team from '../Teams/Teams/Team/Team';
import EditTeam from '../Teams/Teams/Team/EditTeam/EditTeam';
import GoalHierarchy from '../Goals/GoalHierarchy/GoalHierarchy';
import EditGoalHierarchy from '../Goals/GoalHierarchy/EditGoalHierarchy/EditGoalHierarchy';
import Goals from '../Goals/Goals/Goals';
import Goal from '../Goals/Goal/Goal';

function AdministratorLayout() {
  return (
    <Routes>
      <Route index element={<Navigate to="goals" />} />
      <Route path="users/*">
        <Route index element={<AllUsers />} />
        <Route path="invite" element={<InviteUser />} />
        <Route path="administrators" element={<Administrators />} />
        <Route path="users" element={<Users />} />
        <Route path="invite" element={<InviteUser />} />
      </Route>
      <Route path="teams/*">
        <Route index element={<Teams />} />
        <Route path="create" element={<CreateTeam />} />
        <Route path=":teamId">
          <Route index element={<Team />} />
          <Route path="edit" element={<EditTeam />} />
        </Route>
      </Route>
      <Route path="goals/*">
        <Route index element={<Goals />} />
        <Route path="hierarchy">
          <Route index element={<GoalHierarchy />} />
          <Route path="edit" element={<EditGoalHierarchy />} />
        </Route>
        <Route path=":goalId">
          <Route index element={<Goal />} />
          <Route path="edit" element={<p>edit goal</p>} />
          <Route path="measurements">
            <Route index element={<p>goal measurements</p>} />
            <Route path="create" element={<p>create measurement</p>} />
            <Route path="edit" element={<p>edit measurement</p>} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AuthenticatedAdminRoute(AdministratorLayout);
