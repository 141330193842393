import React from 'react';
import useAuth from './useAuth';
import useWebSocket from 'react-use-websocket';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

const UsersContext = React.createContext(null);
const IsTeamLeaderContext = React.createContext(null);
const ConnectionStatusContext = React.createContext(null);
const CommitmentsContext = React.createContext(null);
const PastCommitmentsContext = React.createContext(null);
const MeetingControlsContext = React.createContext(null);
const IsLockedContext = React.createContext(null);
const IsStaleContext = React.createContext(null);
const AttendanceDialogContext = React.createContext(null);
const HasMeetingEndedContext = React.createContext(null);

export function meetingUserSortFn(a, b) {
  const getKey = (u) =>
    `${u.isConnected ? 0 : 1}${u.isLead ? 0 : 1}${u.firstName}${u.lastName}`;

  const aKey = getKey(a);
  const bKey = getKey(b);

  if (aKey < bKey) {
    return -1;
  } else if (aKey > bKey) {
    return 1;
  }
  return 0;
}

export function useUsers() {
  return React.useContext(UsersContext);
}

export function useIsTeamLeader() {
  return React.useContext(IsTeamLeaderContext);
}

export function useConnectionStatus() {
  return React.useContext(ConnectionStatusContext);
}

export function useCommitments() {
  return React.useContext(CommitmentsContext);
}

export function usePastCommitments() {
  return React.useContext(PastCommitmentsContext);
}

export function useMeetingControls() {
  return React.useContext(MeetingControlsContext);
}

export function useIsLocked() {
  return React.useContext(IsLockedContext);
}

export function useIsStale() {
  return React.useContext(IsStaleContext);
}

export function useAttendanceDialog() {
  return React.useContext(AttendanceDialogContext);
}

export function useHasMeetingEnded() {
  return React.useContext(HasMeetingEndedContext);
}

export function MeetingProvider(props) {
  const { children } = props;
  const { teamId, meetingId } = useParams();
  const { currentUser, idToken } = useAuth();
  const navigate = useNavigate();
  const isTeamLeader =
    currentUser.teams?.filter((t) => t.id === teamId && t.isLead === true)
      .length > 0;
  const [isLocked, setIsLocked] = React.useState(false);
  const [isStale, setIsStale] = React.useState(true);
  const [isAttendanceComplete, setIsAttendanceComplete] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [isAttendanceDialogOpen, setIsAttendanceDialogOpen] =
    React.useState(false);
  const [commitments, setCommitments] = React.useState([]);
  const [pastCommitments, setPastCommitments] = React.useState([]);
  const [isConnectionActive, setIsConnectionActive] = React.useState(true);
  const [hasMeetingEnded, setHasMeetingEnded] = React.useState(false);
  const websocketUrl = `${process.env.REACT_APP_MEETINGS_API_URL}?meetingId=${meetingId}`;
  const onOpen = () => {
    sendJsonMessage({
      action: 'GET_STATE',
    });
  };
  const onClose = () => {
    setIsConnectionActive(false);
    setIsStale(true);
  };

  const {
    sendJsonMessage,
    lastJsonMessage,
    readyState: connectionStatus,
    getWebSocket,
  } = useWebSocket(
    websocketUrl,
    {
      protocols: ['Authorization', idToken],
      onOpen,
      onClose,
    },
    isConnectionActive,
  );

  React.useEffect(() => {
    if (lastJsonMessage) {
      switch (lastJsonMessage.action) {
        case 'FULL_UPDATE': {
          const nonCurrentMeetingUsers = [...lastJsonMessage.data.users].filter(
            (u) => u.cognitoId !== currentUser.cognitoId,
          );
          const currentMeetingUsers = [...lastJsonMessage.data.users].filter(
            (u) => u.cognitoId === currentUser.cognitoId,
          );
          const sortedUsers = [
            ...currentMeetingUsers,
            ...nonCurrentMeetingUsers.sort(meetingUserSortFn),
          ];
          setUsers(sortedUsers);
          setCommitments(lastJsonMessage.data.commitments);
          setPastCommitments(lastJsonMessage.data.pastCommitments);
          setIsLocked(lastJsonMessage.data.isLocked);
          setIsStale(false);
          break;
        }
        case 'PARTIAL_UPDATE_LOCK': {
          setIsLocked(lastJsonMessage.data.isLocked);
          break;
        }
        case 'PARTIAL_UPDATE_COMMITMENT': {
          setCommitments((current) => {
            const index = current.findIndex(
              (commitment) => commitment.id === lastJsonMessage.data.id,
            );
            if (index !== -1) {
              const updatedCommitments = [...current];
              const blackListedKeys = ['id', 'cognitoId'];
              Object.keys(lastJsonMessage.data)
                .filter((key) => !blackListedKeys.includes(key))
                .forEach((key) => {
                  updatedCommitments[index][key] = lastJsonMessage.data[key];
                });
              return updatedCommitments;
            }
            return current;
          });
          break;
        }
        case 'PARTIAL_UPDATE_PAST_COMMITMENT': {
          setPastCommitments((current) => {
            const index = current.findIndex(
              (pastCommitment) => pastCommitment.id === lastJsonMessage.data.id,
            );
            if (index !== -1) {
              const updatedPastCommitments = [...current];
              const blackListedKeys = ['id', 'cognitoId'];
              Object.keys(lastJsonMessage.data)
                .filter((key) => !blackListedKeys.includes(key))
                .forEach((key) => {
                  updatedPastCommitments[index][key] =
                    lastJsonMessage.data[key];
                });
              console.log({ updatedPastCommitments });
              return updatedPastCommitments;
            }
            return current;
          });
          break;
        }
        case 'PARTIAL_ADD_COMMITMENT': {
          setCommitments((current) => [...current, lastJsonMessage.data]);
          break;
        }
        case 'PARTIAL_DELETE_COMMITMENT': {
          setCommitments((current) => {
            const index = current.findIndex(
              (commitment) => commitment.id === lastJsonMessage.data.id,
            );
            if (index !== -1) {
              const updatedCommitments = [...current];
              updatedCommitments.splice(index, 1);
              return updatedCommitments;
            }
            return current;
          });
          break;
        }
        case 'PARTIAL_UPDATE_USER': {
          setUsers((current) => {
            const index = current.findIndex(
              (user) => user.cognitoId === lastJsonMessage.data.cognitoId,
            );
            if (index !== -1) {
              // Update existing item
              const updatedUsers = [...current];
              const blackListedKeys = ['cognitoId'];
              Object.keys(lastJsonMessage.data)
                .filter((key) => !blackListedKeys.includes(key))
                .forEach((key) => {
                  updatedUsers[index][key] = lastJsonMessage.data[key];
                });
              return updatedUsers;
            }
            return current;
          });
          break;
        }
        case 'END_MEETING': {
          navigate('/user/commitments/current');
          break;
        }
      }
    }
  }, [lastJsonMessage]);

  React.useEffect(() => {
    setIsAttendanceComplete(users.every((user) => !!user.attendance));
  }, [users]);

  const openAttendanceDialog = () => setIsAttendanceDialogOpen(true);
  const closeAttendanceDialog = () => setIsAttendanceDialogOpen(false);

  const addCommitment = React.useCallback(
    (cognitoId) => {
      sendJsonMessage({
        action: 'ADD_COMMITMENT',
        data: {
          cognitoId,
          commitmentId: uuid(),
          commitment: {
            label: '',
            note: '',
            startValue: 0,
            endValue: 1,
            isTimely: false,
            isSpecific: false,
            isAligned: false,
            isImpactful: false,
            goalId: null,
          },
        },
      });
    },
    [sendJsonMessage],
  );

  const deleteCommitment = React.useCallback(
    (cognitoId, commitmentId) => {
      sendJsonMessage({
        action: 'DELETE_COMMITMENT',
        data: {
          cognitoId,
          commitmentId,
        },
      });
    },
    [sendJsonMessage],
  );

  const updateCommitment = React.useCallback(
    (cognitoId, commitmentId, changes) => {
      sendJsonMessage({
        action: 'UPDATE_COMMITMENT',
        data: {
          cognitoId,
          commitmentId,
          commitment: changes,
        },
      });
    },
    [sendJsonMessage],
  );

  const updatePastCommitment = React.useCallback(
    (cognitoId, commitmentId, changes) => {
      sendJsonMessage({
        action: 'UPDATE_PAST_COMMITMENT',
        data: {
          cognitoId,
          commitmentId,
          commitment: changes,
        },
      });
    },
    [sendJsonMessage],
  );

  const updateAttendance = React.useCallback(
    (cognitoId, attendance) => {
      sendJsonMessage({
        action: 'UPDATE_ATTENDANCE',
        data: {
          cognitoId,
          attendance,
        },
      });
    },
    [sendJsonMessage],
  );

  const changeLock = React.useCallback(
    (lock) => {
      sendJsonMessage({
        action: 'CHANGE_LOCK',
        data: {
          isLocked: !!lock,
        },
      });
    },
    [sendJsonMessage],
  );

  const endMeeting = React.useCallback(() => {
    if (!isAttendanceComplete) {
      openAttendanceDialog();
    } else {
      sendJsonMessage({
        action: 'END_MEETING',
      });
      setHasMeetingEnded(true);
    }
  }, [isAttendanceComplete, openAttendanceDialog, sendJsonMessage]);

  const reconnect = React.useCallback(() => {
    setIsConnectionActive(true);
  }, []);

  const disconnect = React.useCallback(() => {
    getWebSocket().close();
  }, [getWebSocket]);

  return (
    <MeetingControlsContext.Provider
      value={{
        addCommitment,
        updateCommitment,
        updatePastCommitment,
        deleteCommitment,
        updateAttendance,
        changeLock,
        endMeeting,
        reconnect,
        disconnect,
      }}
    >
      <CommitmentsContext.Provider value={commitments}>
        <PastCommitmentsContext.Provider value={pastCommitments}>
          <UsersContext.Provider value={users}>
            <IsTeamLeaderContext.Provider value={isTeamLeader}>
              <ConnectionStatusContext.Provider value={connectionStatus}>
                <IsLockedContext.Provider value={isLocked}>
                  <HasMeetingEndedContext.Provider value={hasMeetingEnded}>
                    <IsStaleContext.Provider value={isStale}>
                      <AttendanceDialogContext.Provider
                        value={{
                          isAttendanceDialogOpen,
                          openAttendanceDialog,
                          closeAttendanceDialog,
                        }}
                      >
                        {children}
                      </AttendanceDialogContext.Provider>
                    </IsStaleContext.Provider>
                  </HasMeetingEndedContext.Provider>
                </IsLockedContext.Provider>
              </ConnectionStatusContext.Provider>
            </IsTeamLeaderContext.Provider>
          </UsersContext.Provider>
        </PastCommitmentsContext.Provider>
      </CommitmentsContext.Provider>
    </MeetingControlsContext.Provider>
  );
}
