import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './AttendanceDialog.module.scss';
import UserAttendanceForm from './UserAttendanceForm/UserAttendanceForm';
import {
  useAttendanceDialog,
  useUsers,
} from '../../../../../../../hooks/useMeeting';
import DialogContentText from '@mui/material/DialogContentText';

export default function AttendanceDialog() {
  const { isAttendanceDialogOpen, closeAttendanceDialog } =
    useAttendanceDialog();
  const users = useUsers();

  const handleClose = () => {
    closeAttendanceDialog();
  };

  return (
    <Dialog
      open={isAttendanceDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.title}>
        Meeting Attendance
      </DialogTitle>
      <div className={styles.content}>
        <DialogContentText id="alert-dialog-description">
          Use the form below to take the attendance for this meeting. Empty
          fields will be populated automatically during the meeting when team
          members join. Changing the attendance for a given user will
          permanently override their attendance status for this meeting.
        </DialogContentText>
        <div className={styles.form}>
          {users.map((user) => (
            <UserAttendanceForm
              key={`${user.cognitoId}-attendance-form`}
              cognitoId={user.cognitoId}
              firstName={user.firstName}
              lastName={user.lastName}
              attendance={user.attendance}
            />
          ))}
        </div>
        <div className={styles.buttons}>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Done
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
