import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { OutlinedInput } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './FormGoalNodeForm.module.scss';
import useTreeEditor, {
  isNumeric,
} from '../../../../../../../hooks/useTreeEditor';
import usePostInitDebouncing from '../../../../../../../hooks/usePostInitDebouncing';

export default function FormGoalNodeForm(props) {
  const {
    id,
    isEditable,
    label,
    description,
    startValue,
    endValue,
    startTime,
    endTime,
  } = props;
  const { updateNode, setHasError } = useTreeEditor();
  const [labelInput, setLabelInput] = React.useState(label);
  const [descriptionInput, setDescriptionInput] = React.useState(description);
  const [startValueInput, setStartValueInput] = React.useState(startValue);
  const [endValueInput, setEndValueInput] = React.useState(endValue);
  const [startTimeInput, setStartTimeInput] = React.useState(
    startTime ? new Date(startTime) : null,
  );
  const [endTimeInput, setEndTimeInput] = React.useState(
    endTime ? new Date(endTime) : null,
  );
  const [labelError, setLabelError] = React.useState(null);
  const [startValueError, setStartValueError] = React.useState(null);
  const [endValueError, setEndValueError] = React.useState(null);
  const [startTimeError, setStartTimeError] = React.useState(null);
  const [endTimeError, setEndTimeError] = React.useState(null);

  React.useEffect(() => {
    if (labelInput.length === 0) {
      setLabelError('Label is required.');
      return;
    }
    if (labelInput.length > 255) {
      setLabelError('Label cannot exceed 255 characters.');
      return;
    }
    if (labelInput.length === 0) {
      setLabelError('Label is required.');
      return;
    }
    setLabelError(null);
  }, [labelInput]);

  React.useEffect(() => {
    if (startValueInput.toString().length === 0) {
      setStartValueError('Start value is required.');
      return;
    }
    if (!isNumeric(startValueInput)) {
      setStartValueError('Start value needs to be a number.');
      return;
    }
    setStartValueError(null);
  }, [startValueInput]);

  React.useEffect(() => {
    if (endValueInput.toString().length === 0) {
      setEndValueError('End value is required.');
      return;
    }
    if (!isNumeric(endValueInput)) {
      setEndValueError('End value needs to be a number.');
      return;
    }
    if (endValueInput.toString() === startValueInput.toString()) {
      setEndValueError('End and start values cannot be equal');
      return;
    }
    setEndValueError(null);
  }, [endValueInput]);

  React.useEffect(() => {
    if (!startTimeInput) {
      setStartTimeError('Start time is required.');
      return;
    }
    setStartTimeError(null);
  }, [startTimeInput]);

  React.useEffect(() => {
    if (!endTimeInput) {
      setEndTimeError('End time is required.');
      return;
    }
    if (startTimeInput && endTimeInput) {
      if (startTimeInput >= endTimeInput) {
        setEndTimeError('End time needs to be after start time.');
        return;
      }
    }
    setEndTimeError(null);
  }, [startTimeInput, endTimeInput]);

  React.useEffect(() => {
    setHasError(
      !!(
        labelError ||
        startValueError ||
        endValueError ||
        startTimeError ||
        endTimeError
      ),
    );
  }, [
    setHasError,
    labelError,
    startValueError,
    endValueError,
    startTimeError,
    endTimeError,
  ]);

  usePostInitDebouncing(() => {
    updateNode(id, {
      label: labelInput,
      description: descriptionInput,
      startValue: startValueInput,
      endValue: endValueInput,
      startTime: startTimeInput,
      endTime: endTimeInput,
    });
  }, [
    id,
    updateNode,
    labelInput,
    descriptionInput,
    startValueInput,
    endValueInput,
    startTimeInput,
    endTimeInput,
  ]);

  return (
    <div className={styles.formGoalNodeForm}>
      <div className={styles.row}>
        <FormControl
          sx={{ m: 1 }}
          error={!!labelError}
          disabled={!isEditable}
          className={styles.formControl}
        >
          <InputLabel id={`${id}-label-label`}>Label</InputLabel>
          <OutlinedInput
            className={styles.input}
            id={`${id}-value-input`}
            label="Label"
            value={labelInput}
            onChange={(e) => setLabelInput(e.target.value)}
          />
          {labelError && <FormHelperText>{labelError}</FormHelperText>}
        </FormControl>
      </div>
      <div className={styles.row}>
        <FormControl
          sx={{ m: 1 }}
          disabled={!isEditable}
          className={styles.formControl}
        >
          <InputLabel id={`${id}-description-label`}>Description</InputLabel>
          <OutlinedInput
            className={styles.input}
            id={`${id}-description-input`}
            label="Description"
            value={descriptionInput}
            onChange={(e) => setDescriptionInput(e.target.value)}
          />
        </FormControl>
      </div>
      <div className={styles.row}>
        <div className={styles.row}>
          <FormControl
            sx={{ m: 1 }}
            error={!!startValueError}
            disabled={!isEditable}
            className={styles.formControl}
          >
            <InputLabel id={`${id}-start-value-label`}>Start value</InputLabel>
            <OutlinedInput
              className={styles.input}
              id={`${id}-start-value-input`}
              label="Start value"
              value={startValueInput}
              onChange={(e) => setStartValueInput(e.target.value)}
            />
            {startValueError && (
              <FormHelperText>{startValueError}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className={styles.row}>
          <FormControl
            sx={{ m: 1 }}
            error={!!endValueError}
            disabled={!isEditable}
            className={styles.formControl}
          >
            <InputLabel id={`${id}-end-value-label`}>End value</InputLabel>
            <OutlinedInput
              className={styles.input}
              id={`${id}-end-value-input`}
              label="End Value"
              value={endValueInput}
              onChange={(e) => setEndValueInput(e.target.value)}
            />
            {endValueError && <FormHelperText>{endValueError}</FormHelperText>}
          </FormControl>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.row}>
          <FormControl
            sx={{ m: 1 }}
            error={!!startTimeError}
            disabled={!isEditable}
            className={styles.formControl}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id={`${id}-start-time-input`}
                value={startTimeInput}
                label="Start Time"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                disabled={!isEditable}
                onChange={(value) => setStartTimeInput(value)}
                slotProps={{
                  textField: {
                    className: styles.input,
                    fullWidth: true,
                    variant: 'outlined',
                    error: !!startTimeError,
                  },
                }}
              />
            </LocalizationProvider>
            {startTimeError && (
              <FormHelperText>{startTimeError}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className={styles.row}>
          <FormControl
            sx={{ m: 1 }}
            error={!!endTimeError}
            disabled={!isEditable}
            className={styles.formControl}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                id={`${id}-start-time-input`}
                value={endTimeInput}
                label="End Time"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                disabled={!isEditable}
                onChange={(value) => setEndTimeInput(value)}
                slotProps={{
                  textField: {
                    className: styles.input,
                    fullWidth: true,
                    variant: 'outlined',
                    error: !!endTimeError,
                  },
                }}
              />
            </LocalizationProvider>
            {endTimeError && <FormHelperText>{endTimeError}</FormHelperText>}
          </FormControl>
        </div>
      </div>
    </div>
  );
}
