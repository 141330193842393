import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import styles from './TableSelectionToolbar.module.scss';
import { Link } from 'react-router-dom';

export default function TableSelectionToolbar(props) {
  const { title, selected, selectedActions, topButtons, clearSelected } = props;
  const selectedCount = selected.length;

  return (
    <div className={styles.tableSelectionToolbar}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.spacer} />
      {(selectedCount > 0 && (
        <>
          <p className={styles.countIndicator}>{selectedCount} selected</p>
          {selectedActions && (
            <div className={styles.buttonGroup}>
              {selectedActions.map((selectedAction) => (
                <Tooltip
                  title={selectedAction.label}
                  key={selectedAction.label}
                >
                  <IconButton
                    onClick={() => {
                      selectedAction.handler(selected);
                      if (selectedAction.clearSelectionAfterAction) {
                        clearSelected();
                      }
                    }}
                  >
                    {selectedAction.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </div>
          )}
        </>
      )) ||
        (topButtons && (
          <div className={styles.buttonGroup}>
            {topButtons.map((button) => {
              const additionalButtonProps = {};
              if (button.to) {
                additionalButtonProps.component = Link;
                additionalButtonProps.to = button.to;
              } else {
                additionalButtonProps.onClick = button.handler || (() => {});
              }
              return (
                <LoadingButton
                  key={button.label}
                  variant={button.variant || 'outlined'}
                  disabled={button.handler === null}
                  color={button.color || 'primary'}
                  loading={button.isLoading}
                  startIcon={button.startIcon}
                  {...additionalButtonProps}
                >
                  {button.label}
                </LoadingButton>
              );
            })}
          </div>
        ))}
    </div>
  );
}
