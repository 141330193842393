import React from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  useCommitments,
  usePastCommitments,
  useUsers,
  useMeetingControls,
  useIsTeamLeader,
  useIsLocked,
  useIsStale,
} from '../../../../../../../hooks/useMeeting';
import styles from './MeetingCommitments.module.scss';
import CommitmentForm from './CommitmentForm/CommitmentForm';
import UserPortrait from '../UserPortrait/UserPortrait';
import Button from '@mui/material/Button';
import useAuth from '../../../../../../../hooks/useAuth';
import PastCommitments from './PastCommitments/PastCommitments';

export default function MeetingCommitments() {
  const { currentUser } = useAuth();
  const users = useUsers();
  const commitments = useCommitments();
  const pastCommitments = usePastCommitments();
  const { addCommitment } = useMeetingControls();
  const isTeamLeader = useIsTeamLeader();
  const isLocked = useIsLocked();
  const isStale = useIsStale();

  return (
    <div className={styles.meetingCommitments}>
      {users.map((user) => {
        const userCommitments = commitments.filter(
          (pc) => pc.cognitoId === user.cognitoId,
        );
        const userPastCommitments = pastCommitments.filter(
          (pc) => pc.cognitoId === user.cognitoId,
        );

        return (
          <div key={`${user.cognitoId}-commitments`} className={styles.user}>
            <div className={styles.header}>
              <UserPortrait
                firstName={user.firstName}
                lastName={user.lastName}
                cognitoId={user.cognitoId}
                isConnected={!isStale && user.isConnected}
                isLead={user.isLead}
                zIndex={1}
              />
              <h3>
                {user.firstName} {user.lastName}
              </h3>
            </div>
            <PastCommitments commitments={userPastCommitments} />

            <div className={styles.commitments}>
              {userCommitments.map((commitment) => {
                return (
                  <CommitmentForm
                    key={commitment.id}
                    commitmentId={commitment.id}
                    cognitoId={commitment.cognitoId}
                    label={commitment.label}
                    note={commitment.note}
                    startValue={commitment.startValue}
                    endValue={commitment.endValue}
                    isTimely={commitment.isTimely}
                    isSpecific={commitment.isSpecific}
                    isAligned={commitment.isAligned}
                    isImpactful={commitment.isImpactful}
                    goalId={commitment.goalId}
                  />
                );
              })}
            </div>
            {((!isLocked && user.cognitoId === currentUser.cognitoId) ||
              isTeamLeader) && (
              <div className={styles.buttons}>
                <Button
                  variant="outlined"
                  onClick={() => addCommitment(user.cognitoId)}
                  startIcon={<AddOutlinedIcon />}
                  disabled={isStale}
                >
                  Add another commitment
                </Button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
