import React from 'react';
import styles from './ConnectedUsersIndicator.module.scss';
import { useIsStale, useUsers } from '../../../../../../../hooks/useMeeting';
import UserPortrait from '../UserPortrait/UserPortrait';

export default React.memo(function ConnectedUsersIndicator() {
  const isStale = useIsStale();
  const users = useUsers();

  return (
    <div className={styles.connectedUsersIndicator}>
      {users.map((user, i, a) => (
        <div
          key={user.cognitoId}
          className={styles.portraitWrapper}
          style={{ zIndex: a.length - i }}
        >
          <UserPortrait
            firstName={user.firstName}
            lastName={user.lastName}
            isLead={user.isLead}
            cognitoId={user.cognitoId}
            isConnected={!isStale && user.isConnected}
          />
        </div>
      ))}
    </div>
  );
});
