import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/_layouts/Layout/Layout';
import AccountLayout from './components/_view/Account/AccountLayout/AccountLayout';
import useAuth, { AuthenticationProvider } from './hooks/useAuth';
import AdministratorLayout from './components/_view/Administrator/AdministratorLayout/AdministratorLayout';
import NotFound from './components/_view/Common/NotFound/NotFound';
import UserLayout from './components/_view/User/UserLayout/UserLayout';
import SupportLayout from './components/_view/Support/SupportLayout/SupportLayout';
import HelpLayout from './components/_view/Help/HelpLayout/HelpLayout';

function RootRedirect() {
  const auth = useAuth();
  if (auth.isAuthenticationComplete) {
    if (auth.isSignedIn) {
      if (auth.isAdministrator) {
        return <Navigate replace to="/administrator" />;
      }
      if (auth.isUser) {
        return <Navigate replace to="/user" />;
      }
    }
    return <Navigate replace to="/account/signIn" />;
  }
  return <Navigate replace to="/404" />;
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <AuthenticationProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<RootRedirect />} />
          <Route path="/account/*" element={<AccountLayout />} />
          <Route path="/administrator/*" element={<AdministratorLayout />} />
          <Route path="/user/*" element={<UserLayout />} />
          <Route path="/support/*" element={<SupportLayout />} />
          <Route path="/help/*" element={<HelpLayout />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthenticationProvider>,
  // </React.StrictMode>,
);
