import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './ConfirmationDialog.module.scss';

export default function ConfirmationDialog({
  confirmText = 'Confirm',
  abortText = 'Cancel',
  onAbort = () => {},
  onFocusLost = () => {},
  isOpen,
  close,
  onConfirm,
  confirmButtonColor,
  abortButtonColor,
  title,
  description,
}) {
  const handleConfirm = () => {
    onConfirm();
    close();
  };

  const handleAbort = () => {
    onAbort();
    close();
  };

  const handleFocusLost = () => {
    onFocusLost();
    close();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleFocusLost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.buttons}>
        <Button
          onClick={handleAbort}
          color={abortButtonColor || 'primary'}
          variant="outlined"
        >
          {abortText}
        </Button>
        <Button
          onClick={handleConfirm}
          color={confirmButtonColor || 'primary'}
          variant="contained"
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
