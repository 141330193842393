import React from 'react';
import styles from './PastCommitmentForm.module.scss';
import TextField from '@mui/material/TextField';
import usePostInitDebouncing from '../../../../../../../../hooks/usePostInitDebouncing';
import useAuth from '../../../../../../../../hooks/useAuth';
import {
  useIsLocked,
  useIsStale,
  useIsTeamLeader,
  useMeetingControls,
} from '../../../../../../../../hooks/useMeeting';
import { isNumeric } from '../../../../../../../../hooks/useTreeEditor';
import FormHelperText from '@mui/material/FormHelperText';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

const TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT = 300;

export default React.memo(function CommitmentForm(props) {
  const { currentUser } = useAuth();
  const { commitmentId, cognitoId, label, note, startValue, endValue, value } =
    props;
  const isTeamLeader = useIsTeamLeader();
  const { updatePastCommitment } = useMeetingControls();
  const isLocked = useIsLocked();
  const isStale = useIsStale();
  const isDisabled =
    isStale ||
    (!isTeamLeader && (currentUser.cognitoId !== cognitoId || isLocked));

  // <value>Current represents the latest value either set locally or received from the server
  const [valueCurrent, setValueCurrent] = React.useState(`${value}`);

  // <valueInput represents the value bound to the input field
  const [valueInput, setValueInput] = React.useState(`${value}`);

  const [valueInputError, setValueInputError] = React.useState(null);

  // Update input value and current value when the props change. This is only triggered when the
  // context for commitments changes AND the prop value changes (due to React.memo() on the component).

  React.useEffect(() => {
    const newValue = `${value}`;
    if (newValue !== valueInput) {
      setValueInput(newValue);
      setValueCurrent(newValue);
    }
  }, [value]);

  usePostInitDebouncing(
    () => {
      if (!valueInputError) {
        if (valueInput !== valueCurrent) {
          setValueCurrent(valueInput);
          updatePastCommitment(cognitoId, commitmentId, {
            value: Number(valueInput),
          });
        }
      }
    },
    [valueInput, valueInputError],
    TEXT_FIELD_INPUT_DEBOUNCE_TIMEOUT,
  );

  const handleValueInputChange = React.useCallback((e) => {
    setValueInput(e.target.value);
  }, []);

  React.useEffect(() => {
    if (!isNumeric(valueInput)) {
      setValueInputError('Value must be a number!');
      return;
    } else {
      const valueInputNumber = Number(valueInput);
      if (
        (startValue < endValue &&
          (valueInputNumber < startValue || valueInputNumber > endValue)) ||
        (startValue > endValue &&
          (valueInputNumber > startValue || valueInputNumber < endValue))
      ) {
        setValueInputError(
          'Value must be between start and end values! If you exceeded the goal of your commitment, use the upper bound (end value).',
        );
        return;
      }
    }
    setValueInputError(null);
  }, [valueInput]);

  const valueTextField = React.useMemo(
    () => (
      <TextField
        label="Value"
        value={valueInput}
        onChange={handleValueInputChange}
        disabled={isDisabled}
        error={!isDisabled && !!valueInputError}
      />
    ),
    [valueInput, valueInputError, isDisabled],
  );

  return (
    <div className={styles.pastCommitmentForm}>
      <div className={styles.row}>
        <div className={styles.stack}>
          <div className={styles.row}>
            <p className={styles.label}>{label}</p>
            <p className={styles.valueRange}>
              ({startValue}
              <span className={styles.icon}>
                <EastOutlinedIcon fontSize="inherit" />
              </span>
              {endValue})
            </p>
          </div>
          {note && <p className={styles.note}>{note}</p>}
        </div>

        <div className={styles.row}>
          <div className={styles.input}>{valueTextField}</div>
        </div>
      </div>
      {!isDisabled && (
        <>
          {[valueInputError]
            .filter((error) => !!error)
            .map((e, i, a) =>
              i !== a.length - 1
                ? e
                : [e, 'Your data will not be saved until you fix the errors!'],
            ) // Append a save warning if there is at least one error in the array
            .flat()
            .map((error) => (
              <FormHelperText error key={error}>
                {`${error}`}
              </FormHelperText>
            ))}
        </>
      )}
    </div>
  );
});
