import Tooltip from '@mui/material/Tooltip';
import styles from './UserPortrait.module.scss';
import React from 'react';
import useAuth from '../../../../../../../hooks/useAuth';

function stringToHue(str) {
  return [...str].reduce((prev, curr) => prev + curr.charCodeAt(0), 0) % 360;
}

export default React.memo(function ConnectedUserPortrait(props) {
  const { currentUser } = useAuth();
  const { firstName, lastName, isLead, isConnected, cognitoId } = props;

  const hue = stringToHue(cognitoId);
  const backgroundColor = isConnected ? `hsl(${hue}, 90%, 97%)` : '#eaeaea';
  const borderColor = isConnected ? `hsl(${hue}, 70%, 80%)` : '#dddddd';
  const color = isConnected ? `hsl(${hue}, 50%, 40%)` : '#999999';
  const statuses = [];
  if (isLead) statuses.push('team leader');
  statuses.push(`${isConnected ? '' : 'not '}connected`);
  const statusText = statuses.length > 0 ? ` (${statuses.join(', ')})` : '';
  const tooltipTitle =
    cognitoId === currentUser.cognitoId
      ? `You${statusText}`
      : `${firstName} ${lastName}${statusText}`;

  return (
    <Tooltip title={tooltipTitle}>
      <div className={styles.portrait}>
        <div
          className={styles.innerWrapper}
          style={{ backgroundColor, borderColor }}
        >
          <p style={{ color, opacity: 0.65 }}>
            {firstName?.[0]}
            {lastName?.[0]}
          </p>
        </div>
        {isConnected && <div className={styles.connectionIndicator} />}
      </div>
    </Tooltip>
  );
});
