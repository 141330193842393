import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styles from './GoalHierarchyForm.module.scss';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export default function GoalHierarchyForm() {
  return (
    <div className={styles.goalHierarchyForm}>
      <Button
        component={Link}
        to={'edit'}
        variant="outlined"
        startIcon={<EditOutlinedIcon />}
      >
        Edit Goal Hierarchy
      </Button>
    </div>
  );
}
