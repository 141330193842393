import React from 'react';
import styles from './ConnectionIndicator.module.scss';
import { ReadyState } from 'react-use-websocket';
import {
  useConnectionStatus,
  useIsLocked,
  useIsStale,
  useMeetingControls,
} from '../../../../../../../hooks/useMeeting';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Button from '@mui/material/Button';

function getStatusText(status, isStale) {
  if (status === ReadyState.OPEN && isStale) {
    return 'Updating';
  }
  return {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Disconnecting',
    [ReadyState.CLOSED]: 'Not Connected',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[status];
}

function getDotClass(status, isStale) {
  if (status === ReadyState.OPEN && isStale) {
    return styles.updating;
  }
  return {
    [ReadyState.CONNECTING]: styles.connecting,
    [ReadyState.OPEN]: styles.connected,
    [ReadyState.CLOSING]: styles.disconnecting,
    [ReadyState.CLOSED]: styles.disconnected,
    [ReadyState.UNINSTANTIATED]: styles.uninstantiated,
  }[status];
}

export default function ConnectionIndicator() {
  const connectionStatus = useConnectionStatus();
  const isLocked = useIsLocked();
  const isStale = useIsStale();
  const { reconnect } = useMeetingControls();

  return (
    <div className={styles.connectionIndicator}>
      <div
        className={`${styles.dot} ${getDotClass(connectionStatus, isStale)}`}
      />
      <p className={styles.label}>{getStatusText(connectionStatus, isStale)}</p>
      {connectionStatus === ReadyState.CLOSED && (
        <Button
          className={styles.reconnectIndicator}
          onClick={reconnect}
          variant="text"
          disableRipple={true}
          sx={{
            padding: 0,
            '&:hover': { backgroundColor: 'initial' },
            '&:active': { backgroundColor: 'initial' },
            '&:focus': { backgroundColor: 'initial' },
          }}
        >
          Reconnect{' '}
          <span className={styles.icon}>
            <CachedOutlinedIcon fontSize={'inherit'} />
          </span>
        </Button>
      )}

      {connectionStatus === ReadyState.OPEN && isLocked && (
        <p className={styles.meetingLockIndicator}>
          Meeting Locked{' '}
          <span className={styles.icon}>
            <LockOutlinedIcon fontSize={'inherit'} />
          </span>
        </p>
      )}
    </div>
  );
}
