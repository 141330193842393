import React from 'react';
import styles from './Privacy.module.scss';
import logo from '../../../../assets/images/glon_logo.svg';
import { getAbsoluteTimestamp } from '../../../../util/formatDate';

const lastUpdated = new Date('2024-09-05T12:00:00.000Z');
const supportEmail = 'support@marceliwac.com';

export default function Privacy() {
  return (
    <div className={styles.privacy}>
      <img src={logo} alt="Glon logo" />
      <div className={styles.topHeader}>
        <h1>Privacy policy</h1>
        <p className={styles.lastUpdated}>
          Last updated: {getAbsoluteTimestamp(lastUpdated)}
        </p>
      </div>
      <p>
        This Privacy Notice for Marceli Wac (&#39;we&#39;, &#39;us&#39;, or
        &#39;our&#39;), describes how and why we might access, collect, store,
        use, and/or share (&#39;process&#39;) your personal information when you
        use our services (&#39;Services&#39;), including when you:
      </p>
      <ul>
        <li>
          Visit our website at <a href="https://glon.app/">glon.app</a>, or any
          website of ours that links to this Privacy Notice
        </li>
        <li>
          Use Glon - a goal-tracking application that aggregates users into
          teams and allows them to create and track their weekly commitments.
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events.
        </li>
      </ul>
      <h3>Questions or concerns?</h3>
      <p>
        Reading this Privacy Notice will help you understand your privacy rights
        and choices. We are responsible for making decisions about how your
        personal information is processed. If you do not agree with our policies
        and practices, please do not use our Services. If you still have any
        questions or concerns, please contact us at:{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </p>
      <div className={styles.keyPoints}>
        <h2>Summary of Key Points</h2>
        <p>
          This summary provides key points from our Privacy Notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>
        <h3>What personal information do we process?</h3>
        <p>
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use.
        </p>
        <h3>Do we process any sensitive personal information?</h3>
        <p>
          Some of the information may be considered &#39;special&#39; or
          &#39;sensitive&#39; in certain jurisdictions, for example your racial
          or ethnic origins, sexual orientation, and religious beliefs. We do
          not process sensitive personal information.
        </p>
        <h3>Do we collect any information from third parties?</h3>
        <p>We do not collect any information from third parties.</p>
        <h3>How do we process your information?</h3>
        <p>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so.
        </p>
        <h3>
          In what situations and with which parties do we share personal
          information?
        </h3>
        <p>
          We may share information in specific situations and with specific
          third parties.
        </p>
        <h3>How do we keep your information safe?</h3>
        <p>
          We have adequate organisational and technical processes and procedures
          in place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorised third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information.
        </p>
        <h3>What are your rights?</h3>
        <p>
          Depending on where you are located geographically, the applicable
          privacy law may mean you have certain rights regarding your personal
          information.
        </p>
        <h3>How do you exercise your rights?</h3>
        <p>
          The easiest way to exercise your rights is by contacting us. We will
          consider and act upon any request in accordance with applicable data
          protection laws. If you would like to learn more about what we do with
          any information we collect, please review the Privacy Notice in full.
        </p>
      </div>
      <h2>1. What Information Do We Collect?</h2>
      <h3>Personal information you disclose to us.</h3>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <h4>Personal Information Provided by You.</h4>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we collect may
        include the following: names email addresses passwords contact or
        authentication data.
      </p>
      <h4>Sensitive Information</h4>
      <p>
        We do not process sensitive information. All personal information that
        you provide to us must be true, complete, and accurate, and you must
        notify us of any changes to such personal information.
      </p>
      <h3>Information automatically collected</h3>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes. The information
        we collect includes:
      </p>
      <ul>
        <li>
          Log and Usage Data - Log and usage data is service-related,
          diagnostic, usage, and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type,
          and settings and information about your activity in the Services (such
          as the date/time stamps associated with your usage, pages and files
          viewed, searches, and other actions you take such as which features
          you use), device event information (such as system activity, error
          reports (sometimes called &#39;crash dumps&#39;), and hardware
          settings).
        </li>
      </ul>
      <h2>2. How Do We Process Your Information?</h2>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <ul>
        <li>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </li>
        <li>
          To deliver and facilitate delivery of services to the user. We may
          process your information to provide you with the requested service.
        </li>
        <li>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </li>
        <li>
          To send administrative information to you. We may process your
          information to send you details about our products and services,
          changes to our terms and policies, and other similar information.
        </li>
        <li>
          To enable user-to-user communications. We may process your information
          if you choose to use any of our offerings that allow for communication
          with another user.
        </li>
        <li>
          To save or protect an individual&#39;s vital interest. We may process
          your information when necessary to save or protect an individual’s
          vital interest, such as to prevent harm.
        </li>
      </ul>
      <h2>3. What Legal Bases Do We Rely on to Process Your Information?</h2>
      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
      </p>
      <ul>
        <li>
          Consent - We may process your information if you have given us
          permission (i.e. consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time.
        </li>
        <li>
          Performance of a Contract - We may process your personal information
          when we believe it is necessary to fulfil our contractual obligations
          to you, including providing our Services or at your request prior to
          entering into a contract with you.
        </li>
        <li>
          Legal Obligations - We may process your information where we believe
          it is necessary for compliance with our legal obligations, such as to
          cooperate with a law enforcement body or regulatory agency, exercise
          or defend our legal rights, or disclose your information as evidence
          in litigation in which we are involved.
        </li>
        <li>
          Vital Interests - We may process your information where we believe it
          is necessary to protect your vital interests or the vital interests of
          a third party, such as situations involving potential threats to the
          safety of any person.
        </li>
      </ul>
      <h2>4. When and With Whom Do We Share Your Personal Information</h2>
      <p>
        We may need to share your personal information in the following
        situations:
      </p>
      <ul>
        <li>
          Business Transfers - We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
        <li>
          Other Users - When you share personal information (for example, by
          posting comments, contributions, or other content to the Services) or
          otherwise interact with public areas of the Services, such personal
          information may be viewed by all users and may be publicly made
          available outside the Services in perpetuity. Similarly, other users
          will be able to view descriptions of your activity, communicate with
          you within our Services, and view your profile.
        </li>
      </ul>
      <h2>5. How Long Do We Keep Your Information</h2>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this Privacy Notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than twelve (12)
        months past the termination of the user&sapos;s account. When we have no
        ongoing legitimate business need to process your personal information,
        we will either delete or anonymise such information, or, if this is not
        possible (for example, because your personal information has been stored
        in backup archives), then we will securely store your personal
        information and isolate it from any further processing until deletion is
        possible.
      </p>
      <h2>6. How Do We Keep Your Information Safe?</h2>
      <p>
        We have implemented appropriate and reasonable technical and
        organisational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorised third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
      <h2>7. Do We Collect Information From Minors?</h2>
      <p>
        We do not knowingly collect, solicit data from, or market to children
        under 18 years of age, nor do we knowingly sell such personal
        information. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at:{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </p>
      <h2>8. What Are Your Privacy Rights?</h2>
      <p>
        In some regions (like the EEA, UK, and Switzerland), you have certain
        rights under applicable data protection laws. These may include the
        right (i) to request access and obtain a copy of your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of your personal information; (iv) if applicable, to data
        portability; and (v) not to be subject to automated decision-making. In
        certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided below.
      </p>
      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your Member State data protection authority or UK data
        protection authority.
      </p>
      <p>
        If you are located in Switzerland, you may contact the Federal Data
        Protection and Information Commissioner.
      </p>
      <h4>Withdrawing your consent</h4>
      <p>
        If we are relying on your consent to process your personal information,
        you have the right to withdraw your consent at any time. You can
        withdraw your consent at any time by contacting us by using the contact
        details provided in the section below. However, please note that this
        will not affect the lawfulness of the processing before its withdrawal
        nor, will it affect the processing of your personal information
        conducted in reliance on lawful processing grounds other than consent.
        Account Information If you would at any time like to review or change
        the information in your account or terminate your account, you can:
        Contact us using the contact information provided. Upon your request to
        terminate your account, we will deactivate or delete your account and
        information from our active databases. However, we may retain some
        information in our files to prevent fraud, troubleshoot problems, assist
        with any investigations, enforce our legal terms and/or comply with
        applicable legal requirements. If you have questions or comments about
        your privacy rights, you may email us at:{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </p>
      <h2>9. Controls for Do-Not-Track Features</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (&#39;DNT&#39;) feature or setting
        you can activate to signal your privacy preference not to have data
        about your online browsing activities monitored and collected. At this
        stage, no uniform technology standard for recognising and implementing
        DNT signals has been finalised. As such, we do not currently respond to
        DNT browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online. If a standard for
        online tracking is adopted that we must follow in the future, we will
        inform you about that practice in a revised version of this Privacy
        Notice.
      </p>
      <h2>10. Do We Make Updates to This Notice</h2>
      <p>
        We may update this Privacy Notice from time to time. The updated version
        will be indicated by an updated &#39;Revised&#39; date at the top of
        this Privacy Notice. If we make material changes to this Privacy Notice,
        we may notify you either by prominently posting a notice of such changes
        or by directly sending you a notification. We encourage you to review
        this Privacy Notice frequently to be informed of how we are protecting
        your information.
      </p>
      <h2>11. How Can You Contact Us About This Notice?</h2>
      <p>
        If you have questions or comments about this notice, you may contact our
        Data Protection Officer (DPO) by email at:{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </p>
      <h2>12. How Can You Review, Update or Delete the Data We Collect?</h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, details
        about how we have processed it, correct inaccuracies, or delete your
        personal information. You may also have the right to withdraw your
        consent to our processing of your personal information. These rights may
        be limited in some circumstances by applicable law. To request to
        review, update, or delete your personal information, please contact us
        at: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
      </p>
    </div>
  );
}
