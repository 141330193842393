import Axios from 'axios';
import { Auth } from 'aws-amplify';

async function tryToRefreshToken(config) {
  try {
    const currentSession = await Auth.currentSession();
    if (currentSession.isValid()) {
      // console.debug('Current session is still valid.');
    } else {
      await Auth.currentAuthenticatedUser().then((user) =>
        user.refreshSession(
          currentSession.getRefreshToken(),
          (e, newSession) => {
            if (!e) {
              return {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${newSession.getIdToken().getJwtToken()}`,
                },
              };
            }
          },
        ),
      );
    }
  } catch (error) {
    console.warn('Could not refresh the token.', error);
  }
  return config;
}

async function setBaseUrlForLocalDevelopment(config) {
  const API_MAP = new Map([
    ['account', 4000],
    ['administrator', 4001],
    ['user', 4002],
  ]);
  const firstCrumb = config.url
    .split('/')
    .filter((s) => s.length > 0)[0]
    .toLowerCase();

  return { ...config, baseURL: `http://localhost:${API_MAP.get(firstCrumb)}` };
}

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  timeout: 20000,
});

instance.interceptors.request.use(tryToRefreshToken);

if (process.env.REACT_APP_VERSION === 'local') {
  instance.interceptors.request.use(setBaseUrlForLocalDevelopment);
}

export default instance;
