import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import styles from './Goals.module.scss';
import useApiData from '../../../../../hooks/useApiData';
import Loading from '../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../Common/Error/Error';
import GoalEntry from './GoalEntry/GoalEntry';

const filterOptions = [
  { label: 'All goals', value: 'all' },
  { label: 'Top goal', value: 'root' },
  { label: 'Team only', value: 'teams' },
  { label: 'Unassigned only', value: 'unassigned' },
];

export default function Goals() {
  const [filter, setFilter] = React.useState(filterOptions[0].value);
  const [filteredGoalsData, setFilteredGoalsData] = React.useState(null);
  const {
    data: goalsData,
    error: goalsError,
    isLoading: goalsIsLoading,
    reload: goalsReload,
  } = useApiData({
    path: `/administrator/goals`,
    params: {
      includeTeams: true,
      includeGoalMeasures: true,
      includeDependencyAndRequiredForIds: true,
    },
  });
  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsIsLoading,
  } = useApiData({
    path: `/administrator/teams`,
    params: {
      includeTeams: true,
      includeTeamMembers: true,
    },
  });

  React.useEffect(() => {
    if (goalsData) {
      setFilteredGoalsData(() => {
        switch (filter) {
          case 'teams':
            return goalsData.filter((d) => d.teams.length > 0);
          case 'unassigned':
            return goalsData.filter((d) => d.teams.length === 0);
          case 'root':
            return goalsData.filter((d) => d.isRoot);
          case 'all':
          default:
            return goalsData;
        }
      });
    }
  }, [goalsData, filter]);

  if (goalsIsLoading) {
    return <Loading message="Fetching goals..." />;
  }

  if (teamsIsLoading) {
    return <Loading message="Fetching teams..." />;
  }

  if (goalsError || goalsData === null) {
    return getErrorComponentFromHttpError(goalsError);
  }

  if (teamsError || teamsData === null) {
    return getErrorComponentFromHttpError(teamsError);
  }

  if (filteredGoalsData === null) {
    return <Loading message="Applying filters..." />;
  }

  return (
    <div className={styles.goals}>
      <div className={styles.header}>
        <h1>Goals</h1>
        <div className={styles.row}>
          <Button
            variant="outlined"
            component={Link}
            to="hierarchy"
            startIcon={<AccountTreeOutlinedIcon />}
          >
            View as hierarchy
          </Button>
          <Button
            variant="outlined"
            component={Link}
            to="hierarchy/edit"
            startIcon={<EditOutlinedIcon />}
          >
            Edit goals
          </Button>
          <Select
            size="small"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className={styles.select}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {filteredGoalsData.map((goal) => (
        <GoalEntry
          key={goal.id}
          id={goal.id}
          goal={goal}
          goals={goalsData}
          teams={teamsData}
          reload={goalsReload}
        />
      ))}
    </div>
  );
}
