import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Form from '../../../../Common/Form/Form';
import APIClient from '../../../../../../util/APIClient';
import styles from './InviteTeamMember.module.scss';
import FormTextField from '../../../../Common/FormTextField/FormTextField';
import FormAlert from '../../../../Common/FormAlert/FormAlert';
import { useParams } from 'react-router-dom';

export default function InviteTeamMember() {
  const { teamId } = useParams();
  const [formAlert, setFormAlert] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);

  async function onSubmit(data) {
    setIsSubmitting(true);
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      teamId,
    };
    try {
      await APIClient.post('/user/users', userData);
      setHasSubmitted(true);
      setFormAlert({
        severity: 'success',
        title: 'Team member invited successfully!',
        message:
          'Team member has been successfully invited. An e-mail will be sent to the new team member asking them to complete registration. They will be added to the team automatically.',
      });
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not invite an team member!',
        message:
          'Something went wrong when inviting the team member. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className={styles.inviteTeamMember}>
      <p className={styles.description}>
        Use this form to invite a new user to the platform. Doing so will create
        an account and send an invitational e-mail that will require the user to
        complete their registration. You need to select at least one group the
        user will belong to.
      </p>
      <Form onSubmit={(data) => onSubmit(data)}>
        <div className={styles.horizontalInputs}>
          <FormTextField
            name="firstName"
            label="First name"
            autocomplete="given-name"
            rules={{
              required: { value: true, message: 'First name is required.' },
            }}
          />
          <FormTextField
            name="lastName"
            label="Last name"
            autocomplete="family-name"
            rules={{
              required: { value: true, message: 'Last name is required.' },
            }}
          />
        </div>
        <FormTextField
          name="email"
          label="E-mail address"
          autocomplete="email"
          rules={{
            required: { value: true, message: 'E-mail address is required.' },
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'E-mail address must be a valid e-mail.',
            },
          }}
        />
        <div className={styles.formControls}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={hasSubmitted}
            fullWidth
          >
            <span>Send invitation</span>
          </LoadingButton>
        </div>
        {formAlert && <FormAlert alert={formAlert} />}
      </Form>
    </div>
  );
}
