import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import useTreeEditor from '../../../../../../../hooks/useTreeEditor';
import styles from './EditGoalHierarchyForm.module.scss';
import APIClient from '../../../../../../../util/APIClient';
import FormAlert from '../../../../../Common/FormAlert/FormAlert';

export default function EditGoalHierarchyForm() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formAlert, setFormAlert] = React.useState(null);
  const { getGoalHierarchy, hasError } = useTreeEditor();
  const navigate = useNavigate();

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      await APIClient.put('administrator/goals/hierarchy', getGoalHierarchy());
      setFormAlert({
        severity: 'success',
        title: 'Goal hierarchy updated successfully!',
        message:
          'Goal hierarchy has been updated successfully. You will be redirected shortly.',
      });
      setTimeout(() => {
        navigate(`/administrator/goals/hierarchy`);
      }, 3000);
    } catch {
      setFormAlert({
        severity: 'error',
        title: 'Could not update goal hierarchy!',
        message:
          'Something went wrong when updating goal hierarchy. Please contact the administrator for further support.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.editGoalHierarchyForm}>
      <LoadingButton
        loading={isSubmitting}
        onClick={handleSave}
        variant="contained"
        disabled={hasError}
        startIcon={<SaveOutlinedIcon />}
      >
        Update Goal Hierarchy
      </LoadingButton>
      {formAlert && <FormAlert alert={formAlert} />}
    </div>
  );
}
