import React from 'react';
import styles from './ProgressBar.module.scss';

export default function ProgressBar(props) {
  const { color, value, trailingDot, trailingDotColor } = props;

  const width = Math.max(Math.min(value * 100, 100), 0);
  const style = {
    width: `${width}%`,
  };
  if (color) {
    style.background = color;
  }
  if (value === 0) {
    style.minWidth = 'unset';
  }

  const trailingDotStyle = {};

  if (trailingDot) {
    if (trailingDotColor) {
      trailingDotStyle.background = trailingDotColor;
    } else if (color) {
      trailingDotStyle.background = `color(from ${color} r g b / .75)`;
    }
  }

  return (
    <div className={styles.progressBar}>
      <div className={styles.outer}>
        <div className={styles.inner} style={style}>
          {trailingDot && value !== 0 && (
            <div className={styles.trailingDot} style={trailingDotStyle} />
          )}
        </div>
      </div>
    </div>
  );
}
