import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import styles from './TeamMembers.module.scss';
import Table from '../../../../Common/Table/Table/Table';
import useApiData from '../../../../../../hooks/useApiData';
import Loading from '../../../../Common/Loading/Loading';
import { getErrorComponentFromHttpError } from '../../../../Common/Error/Error';
import useAuth from '../../../../../../hooks/useAuth';

function teamMemberSortFn(a, b) {
  if (a.isLead && b.isLead) {
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  }
  if (a.isLead) {
    return -1;
  }
  if (b.isLead) {
    return 1;
  }
  if (a.fullName < b.fullName) {
    return -1;
  }
  if (a.fullName > b.fullName) {
    return 1;
  }
  return 0;
}

export default function Team() {
  const { currentUser } = useAuth();
  const { teamId } = useParams();
  const isLead = currentUser.teams.filter((t) => t.id === teamId)?.[0]?.isLead;
  const { data, error, isLoading } = useApiData({
    path: `/user/teams/${teamId}`,
    params: {
      includeGoals: true,
      includeTeamMembers: true,
    },
  });

  if (isLoading) {
    return <Loading message="Fetching team members..." />;
  }

  if (error || data === null) {
    return getErrorComponentFromHttpError(error);
  }

  const userData = data.teamMembers.map((t) => ({
    ...t,
    fullName: `${t.firstName} ${t.lastName}`,
  }));

  userData.sort(teamMemberSortFn);

  return (
    <div className={styles.team}>
      <Table
        rows={userData}
        keyColumn="cognitoId"
        topButtons={
          (isLead && [
            {
              label: 'Edit Team Members',
              to: 'edit',
              startIcon: <EditOutlinedIcon />,
            },
            {
              label: 'Invite New User',
              to: 'invite',
              startIcon: <GroupAddIcon />,
              variant: 'contained',
            },
          ]) ||
          []
        }
        allowSort
        // linkFunction={(id) => `teamMembers/${id}`}
        title={`${data.label}`}
        // rowLinks={{
        //   fullName: {
        //     label: 'View User',
        //     to: (id) => id,
        //   },
        // }}
        columns={[
          {
            id: 'firstName',
            label: 'First Name',
            numeric: false,
          },
          {
            id: 'lastName',
            label: 'Last Name',
            numeric: false,
          },
          {
            id: 'isLead',
            label: 'Team Leader',
            boolean: true,
          },
          {
            id: 'isEnabled',
            label: 'Enabled',
            boolean: true,
          },
        ]}
      />
    </div>
  );
}
