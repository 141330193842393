import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Instructions.module.scss';
import InstructionsSection from './InstructionsSection/InstructionsSection';
import InstructionsSectionImage from './InstructionsSectionImage/InstructionsSectionImage';

import commitments_completed from '../../../../../assets/images/instructions/commitments_completed.png';
import commitments_form from '../../../../../assets/images/instructions/commitments_form.png';
import commitments_in_progress from '../../../../../assets/images/instructions/commitments_in_progress.png';
import password_reset_form from '../../../../../assets/images/instructions/password_reset_form.png';
import password_reset_form_submitted from '../../../../../assets/images/instructions/password_reset_form_submitted.png';
import password_reset_new_password from '../../../../../assets/images/instructions/password_reset_new_password.png';
import signin_form from '../../../../../assets/images/instructions/signin_form.png';
import signup_form from '../../../../../assets/images/instructions/signup_form.png';
import team_configuration_form from '../../../../../assets/images/instructions/team_configuration_form.png';
import team_goals_hierarchy from '../../../../../assets/images/instructions/team_goals_hierarchy.png';
import team_goals_hierarchy_edit from '../../../../../assets/images/instructions/team_goals_hierarchy_edit.png';
import team_goals_hierarchy_edit_menu from '../../../../../assets/images/instructions/team_goals_hierarchy_edit_menu.png';
import team_goals_hierarchy_edit_subgoals from '../../../../../assets/images/instructions/team_goals_hierarchy_edit_subgoals.png';
import team_goals_hierarchy_edit_submitted from '../../../../../assets/images/instructions/team_goals_hierarchy_edit_submitted.png';
import team_goals_hierarchy_revised from '../../../../../assets/images/instructions/team_goals_hierarchy_revised.png';
import team_goals_list from '../../../../../assets/images/instructions/team_goals_list.png';
import team_goals_list_revised from '../../../../../assets/images/instructions/team_goals_list_revised.png';
import team_goals_log_measurement_form from '../../../../../assets/images/instructions/team_goals_log_measurement_form.png';
import team_goals_updated_value from '../../../../../assets/images/instructions/team_goals_updated_value.png';
import team_invite_user_form from '../../../../../assets/images/instructions/team_invite_user_form.png';
import team_invite_user_form_submitted from '../../../../../assets/images/instructions/team_invite_user_form_submitted.png';
import team_meeting from '../../../../../assets/images/instructions/team_meeting.png';
import team_meeting_attendance_form_options from '../../../../../assets/images/instructions/team_meeting_attendance_form_options.png';
import team_meeting_commitment_menu from '../../../../../assets/images/instructions/team_meeting_commitment_menu.png';
import team_meeting_in_progress from '../../../../../assets/images/instructions/team_meeting_in_progress.png';
import team_meeting_new_commitment from '../../../../../assets/images/instructions/team_meeting_new_commitment.png';
import team_meeting_new_commitment_goals from '../../../../../assets/images/instructions/team_meeting_new_commitment_goals.png';
import team_meeting_non_lead_locked from '../../../../../assets/images/instructions/team_meeting_non_lead_locked.png';
import team_meeting_overdue from '../../../../../assets/images/instructions/team_meeting_overdue.png';
import team_meeting_past_commitments_form from '../../../../../assets/images/instructions/team_meeting_past_commitments_form.png';
import team_team_members from '../../../../../assets/images/instructions/team_team_members.png';
import team_team_members_edit from '../../../../../assets/images/instructions/team_team_members_edit.png';
import team_team_members_invited from '../../../../../assets/images/instructions/team_team_members_invited.png';
import logo from '../../../../../assets/images/glon_logo.svg';
import { getAbsoluteTimestamp } from '../../../../../util/formatDate';

const lastUpdated = new Date('2024-09-09T12:00:00.000Z');

export default function Instructions() {
  return (
    <div className={styles.instructions}>
      <img src={logo} alt="Glon logo" className={styles.logo} />
      <div className={styles.topHeader}>
        <h1>User Instructions</h1>
        <p className={styles.lastUpdated}>
          Last updated: {getAbsoluteTimestamp(lastUpdated)}
        </p>
      </div>
      <InstructionsSection title="Glon - a goal-tracking application" id="glon">
        <p>
          Glon is a goal tracking tool that allows an organisation to manage its
          goals across a range of teams. Goals are structured into a hierarchy
          resembling a tree-like structure in which each goal can contain any
          number of sub-goals. Each goal consists of a label, description, start
          and end-values (describing the starting and desired measures) as well
          as start and end-dates. For example, a goal{' '}
          <em>Increase the number of subscribers</em> with a starting value of
          100 and end-value of 200, start date of 1st of January 2024 and an end
          value of 30th of December 2024 describes an objective of increasing a
          subscriber count by 100 (from 100 to 200) between the dates of 1st of
          January and 30th of December 2024.
        </p>
        <p>
          The goal hierarchy starts with a primary goal, which represents the
          overarching aim of the organisation. For an organisation to achieve
          that goal, however, other actions need to happen beforehand. For
          example, to grow the subscriber count from 100 to 200, the
          organisation may wish to produce additional resources, such as
          leaflets, blog posts or videos. This is where the teams and sub-goals
          become a useful.
        </p>
        <p>
          By creating sub-goals, such as{' '}
          <em>Start the new fundraising campaign</em> and{' '}
          <em>Grow the online presence</em>, the organisation can divide the
          main goal and assign specific sub-goals to different teams. For
          example, by assigning <em>Creative</em> team to the{' '}
          <em>Grow the online presence</em> goal, and <em>Fundraising</em> team
          to the <em>Start the new fundraising campaign</em>, the organisation
          leader can delegate the objective of growing the subscriber count to
          its staff. Furthermore, the team members can decide to create further
          sub-goals for the goals they were assigned to break up their
          objectives into more manageable milestones. <em>Creative</em> team may
          for example decide that to grow the organisation&#39;s online
          presence, the would like to expand their blog, write additional news
          articles and run an ad campaign. As such, they can create internal
          sub-goals (called <em>Team Goals</em>) for their assigned goal, with
          specific timelines (start and end-dates) and objectives (start and
          end-values) such as <em>Write blog posts</em> with start value of 0
          and end value of 24 and the date range they deem appropriate.
        </p>
        <p>
          To help in achieving their goals, Glon encourages teams to meet
          regularly by scheduling virtual meetings during which team members
          specify, and commit to completing certain tasks in a given week. Over
          the course of the meeting, each team member creates a list of{' '}
          <em>commitments</em> consisting of a title, an optional note as well
          as start and end-values for that commitment. Each commitment can also
          be linked with a goal assigned to a team, or a more specific team
          goal. As team members continue creating their commitments, the team
          leader can assess their quality by checking the boxes relating to the{' '}
          <em>specificity</em>, <em>timeliness</em>, <em>alignment</em> and{' '}
          <em>impact</em>. The team leader can also adjust the commitments of
          each team member by editing their titles, values or linked goals. When
          all of the team members are satisfied with their commitments for the
          upcoming week, the team leader can <em>lock</em> the meeting to
          prevent them from making further changes and conduct a final review of
          the created commitments. During that time, only team leaders are able
          to edit the existing commitments. Finally, when all changes the
          commitments and their quality indicators are complete, the team
          leaders can conclude the meeting by ending it for all attendees.
          Attendance will be tracked automatically for all team members who
          joined the virtual meeting and in the cases of absence of any of the
          team members, the attendance dialog will pop-up automatically prior to
          the meeting ending. Team leaders will also have the ability to
          override the attendance status for each of the team members at any
          point during the meeting. Following the initial meeting, each
          subsequent meeting will also display the previously created
          commitments, allowing team members to make final changes to their
          completion status.
        </p>

        <p>
          Each of the four quality measures is designed to ensure that the
          created commitments are concrete, actionable and worth pursuing in the
          first place. A <em>Specific</em> goal is one that defines the
          commitment&#39;s scope without any ambiguity of what constitutes its
          achievement. A commitment such as <em>Create leaflets</em> may not be
          sufficiently specific;{' '}
          <em>Create content for leaflets about the fundraising campaign X</em>{' '}
          describes the scope much more accurately. Each commitment should also
          be <em>Timely</em>, or in other words achievable by the next meeting.
          In certain cases, tasks may take longer than a week to complete. For
          example, commiting to{' '}
          <em>Write a 200-page report on fundraising impacts</em>, would be
          unrealistic task to complete within a span of the week, but that task
          could be broken down into smaller, more manageable &quot;chunks&quot;
          such as <em>Create the structure of the report</em>,{' '}
          <em>Write a section on introduction to fundraising strategies</em>{' '}
          etc. Tasks that are expected to last multiple weeks, such as the one
          described above, are better suited for becoming team goals than
          commitments, and should be captured as such by the team leaders - then
          referenced by the team member&#39;s commitments as a linked goal. To
          ensure that a specific commitment fits within objectives of a team,
          they should be <em>Aligned</em> to a specific goal. A well-aligned
          commitment is therefore likely link to a specific goal assigned to a
          team. For example, commiting to <em>Send emails</em> does not relate
          to any particular goal, while commiting to{' '}
          <em>
            Send emails to existing subscribers showcasing latest blog posts
          </em>{' '}
          may be crucial for increasing the subscriber count and, more
          specifically, <em>Growing the online presence</em>.
          <em>Impactful commitments</em>, on the other hand, relate to carrying
          value for the organisation and being worth pursuing in the first
          place. Commitments which are both specific and aligned are also likely
          (though not guaranteed) to also be impactful. For example, commiting
          to{' '}
          <em>
            Translate the help section of the website from English to Gaelic
          </em>{' '}
          is both specific and aligned (to increasing the online presence), but
          it may not be worth pursuing, or impactful if the target audience only
          speaks English.
        </p>
        <p>
          Following the end of the meeting, all commitment created during the
          meeting will appear under the <em>Current Commitments</em> tab of the{' '}
          <em>Commitments</em> section. From that page, the team members will be
          able to update the progress of their commitment over the course of the
          week, prior to the next meeting. Additionally, they will also be able
          to update the final values of their current commitments during the
          next meeting.
        </p>
      </InstructionsSection>
      <InstructionsSection title="Sign-Up and Sign-In" id="signup_signin">
        <p>
          To access the platform, each user will need to first create an
          account. This can be done by each user individually, buy completing
          the <Link to="/account/signup">Sign-Up Form</Link>, or by being
          invited to join the platform by one of the administrators or team
          leaders. In the latter case, the users will be added to the team
          automatically.
        </p>
        <InstructionsSectionImage
          src={signup_form}
          alt="Screenshot of the sign-up form."
          description="Sign-up form for creating an account can be used to self-register for an account."
        />
        <p>
          Following the registration (or being invited to Glon), users will
          receive a confirmation link in their e-mail inbox. They will need to
          follow the included link to complete the sign-up prior to accessing
          Glon.
        </p>

        <p>
          <em>
            In some cases, the sign-up email can be treated as SPAM or Junk.
            Users should therefore ensure that they have checked their SPAM or
            Junk folder, if the email does not arrive within couple of minutes
            from the registration or invitation.
          </em>
        </p>
        <p>
          When visiting the included link, each user may be presented with a
          form that prompts them to confirm the sign-up, or set a new password
          for their account prior to signing in.
        </p>
        <p>
          To sign in, users should input their credentials consisting of the
          e-mail address used during the registration or invitation, together
          with their password into the{' '}
          <Link to="/account/signin">Sign-In Form</Link>.
        </p>
        <InstructionsSectionImage
          src={signin_form}
          alt="Screenshot of the sign-in form."
          description="Prior to accessing Glon, users will need to authenticate themselves using the sign-in form."
        />
      </InstructionsSection>

      <InstructionsSection title="Resetting Password" id="password_reset">
        <p>
          In the cases where users forget their password, they will be able to
          reset it by completing the{' '}
          <Link to="/account/reset-password">Password Reset Form</Link>, which
          could also be accessed from the link displayed in the Sign-In screen.
        </p>

        <InstructionsSectionImage
          src={password_reset_form}
          alt="Screenshot of the password reset form."
          description="Password Reset form can be used to reset the password for the existing account."
        />
        <p>
          Upon completing the form, the user will be prompted to follow the
          instructions delivered to their e-mail account.
        </p>
        <InstructionsSectionImage
          src={password_reset_form_submitted}
          alt="Screenshot of the password reset form with a submitted banner."
          description="Submitting the password reset request will send the e-mail to the account the user has registered with."
        />
        <p>
          <em>
            In some cases, the password-reset email can be treated as SPAM or
            Junk. Users should therefore ensure that they have checked their
            SPAM or Junk folder, if the email does not arrive within couple of
            minutes from the registration or invitation.
          </em>
        </p>
        <p>
          After following the instructions, user will be required to create a
          new password using the link from their e-mail. This can be done by
          completing the form which was linked to from the original message and
          providing new password.
        </p>
        <InstructionsSectionImage
          src={password_reset_new_password}
          alt="Screenshot of the new password setup form."
          description="Completing the password reset process will require the user to follow the provided instructions and setting the new password."
        />
      </InstructionsSection>

      <InstructionsSection title="Goals" id="goals">
        <p>
          Goals assigned to a team can be viewed by navigating to the{' '}
          <em>Goals</em> tab of the <em>Team</em> section. By default a
          list-view of the assigned goals will be displayed, describing each of
          the goals, their start and end-values together with a progression
          graph that depicts the completion status over the goal&#39;s span of
          time.
        </p>

        <InstructionsSectionImage
          src={team_goals_list}
          alt="Screenshot of the goal list for a team."
          description="Goals assigned to a team can be viewed from the Goals section of the Team menu, for each of the teams a user is a member of."
        />
        <p>
          Alternatively, team members can view the goal hierarchy (or
          hierarchies, if multiple unrelated goals are assigned to a team) by
          pressing the <em>View as Hierarchy</em> button on the
        </p>

        <InstructionsSectionImage
          src={team_goals_hierarchy}
          alt="Screenshot of the goal hierarchy for a team."
          description="Goals assigned to a team can be viewed as a hierarchy."
        />

        <p>
          Team leaders are able to update the progress of the goals assigned to
          their team by logging the new measurements for specific goals. Logging
          the new measurements will require them to fill out the form consisting
          of the value and date on which it should be logged.
        </p>

        <InstructionsSectionImage
          src={team_goals_log_measurement_form}
          alt="Screenshot of the goal measurement update form."
          description="Goal values can be updated by filling out the Log Measurement form for a goal."
        />

        <InstructionsSectionImage
          src={team_goals_updated_value}
          alt="Screenshot of the goal hierarchy with updated value."
          description="Logging a new measurement will update the current completion status of the goal."
        />
        <p>
          Team leaders can also create and edit team goals by editing the
          assigned goal hierarchy. Created goals will be saved as team goals,
          which cannot be unassigned from the team and should represent the
          sub-goals of the goals assigned to the team by the administrators.
        </p>

        <InstructionsSectionImage
          src={team_goals_hierarchy_edit}
          alt="Screenshot of the goal hierarchy editing screen for a team."
          description="Goal hierarchies for a team can be edited by the team leaders. This includes editing, removing and creating new team goals."
        />

        <p>
          To create a new goal, or delete an existing team goal, team leaders
          can press the three-dot menu button next to the goal.
        </p>

        <InstructionsSectionImage
          src={team_goals_hierarchy_edit_menu}
          alt="Screenshot of the goal hierarchy editing screen for a team with menu open."
          description="New goals can be created and existing goals can be removed from the goal menu opened using the three-dot indicator next to the goal's form."
        />

        <p>
          Creating sub-goals for a goal assigned by the administrator, will
          result in creating a team goal; each team goal can also have any
          number of sub-goals of its own. Goals cannot be moved within the
          hierarchy, instead, they need to be re-created.
        </p>

        <InstructionsSectionImage
          src={team_goals_hierarchy_edit_subgoals}
          alt="Screenshot of the goal hierarchy with updated goals."
          description="Sub-goals created by the team leaders will become team goals."
        />

        <p>
          To save the updated hierarchy, the team leaders need to ensure that
          all goals&#39; fields have been filled out and no errors are present.
          Following that, the hierarchy can be saved by pressing the save
          button. Upon completion, an alert will be show to inform them about
          the outcome of the operation.
        </p>

        <InstructionsSectionImage
          src={team_goals_hierarchy_edit_submitted}
          alt="Screenshot of the goal hierarchy with successful submission alert."
          description="Saving a goal hierarchy will display an alert informing users about the outcome of the operation."
        />

        <p>
          After updating the goal hierarchy, the new goals will become
          immediately available under the <em>Goals</em> section of the{' '}
          <em>Team</em> tab. Newly created team goals will also show up in the
          linked goal dropdown during the meeting.
        </p>

        <InstructionsSectionImage
          src={team_goals_list_revised}
          alt="Screenshot of the updated goal list."
          description="Updated goals will become immediately available under the goals section."
        />
        <InstructionsSectionImage
          src={team_goals_hierarchy_revised}
          alt="Screenshot of the updated goal hierarchy."
          description="The newly created goals will become team goals, as displayed in the goal hierarchy."
        />
      </InstructionsSection>
      <InstructionsSection title="Meetings Overview" id="meetings_overview">
        <p>
          Team members are able to see their past and upcoming meetings in the{' '}
          <em>Meetings</em> section of the <em>Team</em> tab. Depending on
          whether the team has already met during this week, the page will
          display the banner describing the status of the current meeting as
          either upcoming, in-progress or overdue. Team leaders will
          additionally see the button allowing them to start the meeting for the
          current week. Additionally, banners will be displayed to inform the
          users of any future scheduled meetings.
        </p>

        <InstructionsSectionImage
          src={team_meeting_overdue}
          alt="Screenshot of the meetings list screen highlighting the overdue meeting."
          description="Meetings list screen will display information about the current and scheduled meetings, allowing team leaders to start the meeting for the current week."
        />

        <p>
          If the meeting is in progress, team members will have the option to
          join it by pressing the <em>Join Meeting</em> button on the active
          meeting&#39;s banner.
        </p>

        <InstructionsSectionImage
          src={team_meeting_in_progress}
          alt="Screenshot of the meetings list screen highlighting the meeting in progress."
          description="Meetings list currently in progress can be joined by pressing the appropriate button on the meeting information banner."
        />
      </InstructionsSection>
      <InstructionsSection title="Attending a Meeting" id="attending_meeting">
        <p>
          Virtual meetings allow the team members to create commitments for the
          current week and update the final values for the commitments created
          in the past. During the meeting any edits to the commitment forms will
          be automatically synchronised across all team members present in the
          meeting. This allows them to collaborate with other team members in
          real-time, discuss the quality of their commitments and effectively
          schedule work for the coming week.
        </p>

        <InstructionsSectionImage
          src={team_meeting}
          alt="Screenshot of the meeting screen."
          description="Any changes performed to the commitment forms will be automatically synchronised across all meeting attendees."
        />

        <p>
          New commitments can be created by each of the team members using the{' '}
          <em>Add another commitment</em> button. Making any changes to the
          commitment will automatically save its contents and synchronise them
          with other attendees without the need to manually or explicitly save
          them.
        </p>

        <InstructionsSectionImage
          src={team_meeting_new_commitment}
          alt="Screenshot of the meeting screen with new commitment form."
          description="New commitments can be created by each of the team members using the{' '}
          Add another commitment button."
        />

        <p>
          To remove the existing commitment, team members can press the
          three-dot menu located next to the commitment and selecting the
          appropriate option.
        </p>

        <InstructionsSectionImage
          src={team_meeting_commitment_menu}
          alt="Screenshot of the meeting screen with new commitment menu open."
          description="Commitments can be removed by pressing the three-dot menu next to the commitment and selecting appropriate option."
        />

        <p>
          Existing goals assigned to the team by the administrator, as well as
          team goals created by the team leader will be available under the
          optional <em>Goal</em> dropdown. Ideally, commitments should indicate
          a specific goal they are linked to.
        </p>

        <InstructionsSectionImage
          src={team_meeting_new_commitment_goals}
          alt="Screenshot of the meeting screen with the goals dropdown active."
          description="Each commitment should idealy link to a specific goal assigned to a team."
        />

        <p>
          During the meeting, team members can also review their previous
          meeting&#39;s commitments and ensure that their final values represent
          the degree to which they were completed.
        </p>

        <InstructionsSectionImage
          src={team_meeting_past_commitments_form}
          alt="Screenshot of the locked meeting screen with the updated commitment form."
          description="Team leaders can lock the meeting to prevent non-team leaders from making further changes. They themselves can however make changes to any team member's commitments, even when the meeting is locked."
        />
      </InstructionsSection>
      <InstructionsSection title="Meeting Controls" id="meeting_controls">
        <p>
          Team leaders have additional controls that allow them to manage the
          current state of the meeting, taking attendance and assessing the
          quality of the commitments created by all of the team members.
          Additionally, team leaders can also create, edit and remove the
          existing commitments of any of the other team members. This also
          applies to the team members who were not able to join the call,
          allowing them to create commitments for absent team members.
        </p>
        <p>
          When the team leader decides to end the meeting, the attendance will
          be taken automatically for each team member present during the
          meeting. However, should any team members be absent, or unable to join
          the meeting the attendance dialog will be automatically displayed,
          prompting them to fill take attendance for the missing team members.
        </p>

        <InstructionsSectionImage
          src={team_meeting_attendance_form_options}
          alt="Screenshot of the locked meeting screen with the attendance dialog open."
          description="Prior to the end of the meeting, team leaders need to ensure that the attendance form has been completed and that attendance has been taken for each of the team members."
        />

        <p>
          Locking the meeting will prevent all non-team leaders from making
          further changes to any of the commitments, while still allowing the
          team leaders to freely adjust the quality indicators and contents for
          each of the commitments. This ensures that the final state of the
          commitments can be controlled by the team leaders.
        </p>

        <InstructionsSectionImage
          src={team_meeting_non_lead_locked}
          alt="Screenshot of the locked meeting screen with the updated commitment form."
          description="Team leaders can lock the meeting to prevent non-team leaders from making further changes. They themselves can however make changes to any team member's commitments, even when the meeting is locked."
        />

        <p>
          Ending the meeting will conclude it for all connected team members and
          re-direct them to their <em>Current Commitments</em> screen. At this
          point, all created commitments will be saved and only their values
          will be accessible for update by the respective team members to which
          they are assigned.
        </p>
      </InstructionsSection>
      <InstructionsSection title="Management of Team Members" id="team_members">
        <p>
          Team leaders have additional control over the configuration of the
          team, as well as the team members. They can invite new team members to
          Glon, who upon registering will be automatically added to the team, or
          add existing users to their team.
        </p>
        <InstructionsSectionImage
          src={team_team_members}
          alt="Screenshot of the team's members screen."
          description="Team leaders view the list of existing team members using Team Members section of the Team tab."
        />

        <p>
          To invite the team member, the team leader needs to press the{' '}
          <em>Invite New User</em> button and fill out the form with the details
          of the new user. This will only work if the user with the provided
          e-mail address has not yet registered on the platform.
        </p>

        <InstructionsSectionImage
          src={team_invite_user_form}
          alt="Screenshot of the team's member invitation screen."
          description="Team leaders can invite new users to Glon."
        />

        <p>
          Following the form submission, an e-mail will be sent to the indicated
          e-mail address inviting the user to complete the registration and set
          a new password for their account. Additionally, team members invited
          to Glon using this method will be added to the team automatically.
        </p>

        <InstructionsSectionImage
          src={team_invite_user_form_submitted}
          alt="Screenshot of the team's member invitation screen with a submission confirmation."
          description="Submitting the Invite new user form will send the e-mail to indicated address, prompting the prospective user to complete registration."
        />

        <InstructionsSectionImage
          src={team_team_members_invited}
          alt="Screenshot of the team's member invitation screen with a submission confirmation."
          description="Team members invited by the team leaders will join the team automatically."
        />

        <p>
          Additionally, the have the control over changing existing team
          members&#39;s access level by adding or removing them to and from the
          list of team leaders.
        </p>

        <InstructionsSectionImage
          src={team_team_members_edit}
          alt="Screenshot of the team's members editing screen."
          description={
            'Team leaders can add and remove existing users from the team\'s members list and adjust their "team leader" status.'
          }
        />
      </InstructionsSection>
      <InstructionsSection title="Team Configuration" id="team_configuration">
        <p>
          In addition to the team member management, team leaders are also
          allowed to change the team configuration, which describes the time
          during which the weekly meetings occur. Changing this configuration
          will take immediate effect and use the current user&#39;s time zone.
          Existing meetings which have not yet started will be re-scheduled to
          the new times.
        </p>

        <InstructionsSectionImage
          src={team_configuration_form}
          alt="Screenshot of the team's configuration form."
          description={
            'Team leaders can adjust the meeting configuration which describes the meeting times for that team.'
          }
        />
      </InstructionsSection>
      <InstructionsSection title="Commitments" id="commitments">
        <p>
          Every user can access their list of both current and previous
          commitments by navigating to the <em>Commitments</em> tab. In there,
          they are able to see all the commitments they have made in the
          previous meetings across all the teams they belong to.
        </p>

        <InstructionsSectionImage
          src={commitments_in_progress}
          alt="Screenshot of the commitments screen."
          description={
            'List of current and previous commitments are available under the Commitments tab.'
          }
        />
        <p>
          Within the <em>Current Commitments</em> section, each of the
          commitments includes an icon button allowing the user to update the
          completion status of that commitment by providing a new value for that
          commitment.
        </p>

        <InstructionsSectionImage
          src={commitments_form}
          alt="Screenshot of the commitments screen with commitment update dialog open."
          description={
            'Every user can update the value for their current commitments that describes the completion state of that commitment.'
          }
        />
        <p>
          Updating the value for the commitment will change its status,
          depending on the start and end values of the commitment. These values
          can only be updated for the commitments which have not yet expired (or
          those created during the most recent meeting).
        </p>

        <InstructionsSectionImage
          src={commitments_completed}
          alt="Screenshot of the commitments screen with updated commitment value."
          description={
            'Updating the value for the commitment will change its status, depending on the start and end values of the commitment.'
          }
        />
      </InstructionsSection>
    </div>
  );
}
